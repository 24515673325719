/**
 * 模块名称: vr设备统计
 * @author zhuyan@372163.com
 */
import React, { useState, useEffect, useMemo, useRef } from 'react'
import api from '@/api'
import HeaderSearch from '@/components/HeaderSearch'
import {
  Table,
  Form,
  Input,
  Select,
  Spin,
} from 'antd'
import Auth from '@/components/AuthMiddleware'

const pageIndex = 1
const perPage = 10
let searchParam = {
  page: pageIndex,
  limit: perPage,
}
const { Option } = Select

const Stats = (props) => {
  const { match, location, history } = props
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(pageIndex)
  const [limit, setPageSize] = useState(perPage)
  const [total, setTotal] = useState(0)
  const [dataSource, setDataSource] = useState([])
  const [queryData, setQueryData] = useState({})
  const [columns, setColumns] = useState([])

  useEffect(() => {
    resetParams()
    onSelectAll()
    getList()
  }, [])

  const resetParams = () => {
    searchParam = {
      page: pageIndex,
      limit: perPage,
    }
    setPageSize(perPage)
  }

  const onSelectAll = () => {
    api.getDeviceQueryData().then(res => {
      setQueryData(res)
    })
  }

  const getList = values => {
    if (values) {
      const { page, limit } = searchParam
      searchParam = { page, limit, ...values }
    }
    let params = { ...searchParam }
    setLoading(true)
    api.getVrDeviceStatisticsList(params).then(data => {
      setPage(searchParam.page)
      setPageSize(searchParam.limit)
      setTotal(data.count)
      const columnsData = data.header.map(header => ({
        title: header,
        dataIndex: header,
        align: 'center',
        render: (text, record) => {
          const nonLinkHeaders = ['项目名称', '运营方式', '租金', '租赁周期']
          if (!nonLinkHeaders.includes(header)) {
            const state = {
              currentHeader: header
            }
            if (!['库存', '合计'].includes(record['项目名称'])) {
              state.projectName = record['项目名称']
              state.record = record
            } else if (record['项目名称'] === '库存') {
              state.currentDeviceStatus = 1
            }
            return <a onClick={() => history.push({
              pathname: '/vr/stock',
              state: state
            })}>{text}</a>
          }
          return <div>{text}</div>
        }
      }))
      setColumns(columnsData)
      setDataSource(data.list)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  const onChangeTable = (pagination, filters, sorter) => {
    searchParam.page = pagination.current
    searchParam.limit = pagination.pageSize
    getList()
  }

  const searchItems = useMemo(() => {
    let searchTempArr = [
      {
        label: '设备名称',
        name: 'deviceName',
        component: <Input placeholder="请输入设备名称" />,
      },
      {
        label: '关联项目',
        name: 'projectName',
        component: (
          <Select placeholder="全部"
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
          >
            <Option value="select-all">全部</Option>
            {
              queryData.projectNameList?.map((v, i) => {
                return <Option value={v.id} key={i}>{v.name}</Option>
              })
            }
          </Select>
        ),
      },
      {
        label: '运营方式',
        name: 'operationMode',
        component: (
          <Select placeholder="全部"
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
          >
            <Option value="select-all">全部</Option>
            {
              queryData.operationModeList?.map((v, i) => {
                return <Option value={v.id} key={i}>{v.name}</Option>
              })
            }
          </Select>
        ),
      },
    ]

    return { search: searchTempArr }
  }, [queryData])

  return (
    <div className="vrdevice-stock-container">
      <Spin spinning={loading} tip="Loading...">
        <HeaderSearch
          {...searchItems}
          getList={getList}
          resetParams={resetParams}
          hasShowAll={false}
        ></HeaderSearch>

        <div className='line'></div>
        <div className="vrdevice-stock-btn">
        </div>
        <Table
          size="small"
          columns={columns}
          rowKey={(record, i) => i}
          dataSource={dataSource}
          scroll={{ x: 'max-content' }}
          pagination={{
            defaultCurrent: pageIndex,
            pageSize: limit,
            total: total,
            showSizeChanger: true,
            showQuickJumper: true,
            current: page,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          onChange={onChangeTable}
        ></Table>
      </Spin>
    </div>
  )
}

export default Form.create()(Stats)
