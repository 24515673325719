/** antd上传组件公共方法（阿里云）
** 支持回调函数 callback
*/
import { message } from 'antd'
import ReactDOM from 'react-dom'
import api from '@/api'

export const upLoadModuleAli = (json) => {
  if (!json.fileList) return message.warning('fileList为必传项，请补充', 3)
  if (!json.setFileList) return message.warning('setFileList为必传项，请补充', 3)
  if (!json.draggerRef) return message.warning('draggerRef为必传项，请补充', 3)
  let name = json.name || 'file'
  //默认支持大多数格式
  let accept = json.accept || '.rar, .zip, .doc, .docx, .pdf, .jpg, .jpeg, .png, .bmp, .gif, .pptx, .PDF, .csv, .xlsx'
  // const action = "https://oasys.oss-cn-beijing.aliyuncs.com/"
  const action = json.oss.host
  let allowExts = accept.replace(/\./g, '').replace(/\ /g, '')
  let allowSizeType = json.allowSizeType || 2 //注释见下方
  let fileList = json.fileList //必传项
  let limitNum = json.limitNum || 5 //最多上传的个数
  let draggerRef = json.draggerRef //必传项
  let setFileList = json.setFileList//必传项
  let multiple = json.multiple || false//是否支持多选 默认不支持
  let showRemoveIcon = json.showRemoveIcon || true //是否展示移除按钮，默认展示
  let showDownloadIcon = json.showDownloadIcon || false //是否展示下载按钮，默认不展示
  let showPreviewIcon = json.showPreviewIcon || false //是否展示列表按钮，默认不展示
  let limitSize = () => {
    let s = 5 //5M 为默认2
    switch (allowSizeType) {
      case 1:
        s = 3 //3M 传1
        break
      case 2:
        s = 5 //5M 传2
        break
      case 3:
        s = 10 //10M 传3
        break
      case 4:
        s = 15 //15M 传4
        break
      case 5:
        s = 2 //2M 传5
        break
      case 6:
        s = 50 //50M 传6
        break
      case 7:
        s = 200 //200M 传7
        break
      case 8:
        s = 20 //20M 传8
        break
      default:
        s = 5
    }
    // console.log(s)
    return s
  }

  const upData = (file) => {
    return {
      name: file.name,
      key: json.oss.dir + file.name,
      OSSAccessKeyId: json.oss.accessid,
      policy: json.oss.policy,
      Signature: json.oss.signature,
      callback: json.oss.callback,
      success_action_status: '200',
    };
  }

  return {
    name: name,
    multiple: multiple,
    showUploadList: {
      showPreviewIcon: showPreviewIcon,
      showRemoveIcon: showRemoveIcon,
      showDownloadIcon: showDownloadIcon
    },
    action: action,
    accept: accept,
    headers: { token: localStorage.getItem('token') },
    data: upData,
    defaultFileList: fileList,
    onRemove(file) {
      if (json.import) {
        let url = null
        for (let i = 0; i < fileList.length; i++) {
          if (file.uid === fileList[i].uid) url = fileList[i].url
        }
        api.fileDelFile({ fileUrl: url }).then(() => {
          //message.success('删除成功')
        })
      }
      setFileList(fileList.filter(item => item.uid !== file.uid))
    },
    beforeUpload(file) {
      if (json.accept) {
        let type = file.name.substring(file.name.lastIndexOf('.'))
        if (!json.accept.includes(type)) {
          message.error(`只支持上传${json.accept}文件`)
          return false
        }
      }

      if (json.import) {
        if (fileList.length >= 1) {
          message.warning('上传附件个数不得超过1个')
          return false
        }
      } else {
        if (fileList.length >= limitNum) {
          message.warning(`上传附件个数不得超过${limitNum}个`)
          return false
        }
      }

      if (file.size / 1024 / 1024 > limitSize()) {
        message.warning(`单个文件大小不能超过${limitSize()}M`)
        return false
      }

      if (file.name.indexOf(',') !== -1) {
        // message.warning('文件名有误')
        message.warning('文件名不可以有逗号')
        return false
      }
    },
    onChange(info) {
      // console.log(info)
      let timer = setTimeout(() => {
        //删除上传错误的file
        const dr = ReactDOM.findDOMNode(draggerRef.current)
        if (dr) {
          const list = dr.querySelector('.ant-upload-list')
          let wrongLoad = dr.querySelectorAll('.ant-upload-list-item-undefined')
          wrongLoad.forEach((item, index, self) => {
            let wrongLoadParent = self[index].parentNode.parentNode
            // self[index].style.display = 'none'
            list.removeChild(wrongLoadParent)
          })
        }
        clearTimeout(timer)
      }, 500)
      if (info.file.size / 1024 / 1024 > limitSize()) {
        return false
      }
      const { status } = info.file
      if (status !== 'uploading') {
      }
      if (status == 'done') {
        if (info.file.response.Status == "Ok") {
          setFileList([...fileList, { uid: info.file.uid, name: info.file.name, url: `${json.oss.host}${json.oss.dir}${info.file.name}`, status: 'done' }])
          message.success(`上传成功`, 2)
          json.callback && json.callback()
        } else {
          //删除上传type错误的file
          const dr = ReactDOM.findDOMNode(draggerRef.current)
          if (dr) {
            const list = dr.querySelector('.ant-upload-list')
            const len = list.querySelectorAll('.ant-upload-list-item').length
            list.removeChild(list.querySelectorAll('.ant-upload-list-item')[len - 1].parentNode.parentNode)
            // message.error(info.file.response.msg)
            message.error("上传失败")
          }
        }
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  }
}