/**
 * 模块名称: 设备维修完成申请
 * @author zhuyan@372163.com
 */
import React, { useEffect, useState } from 'react'
import { Form, Input, InputNumber, Button, message } from 'antd'
import api from '@/api'
import '../assets/module.scss'

const { TextArea } = Input
const FormItem = Form.Item

const RepairFinishForm = (props) => {
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const { setRepairFinishVisible, getList, repairFinishType, operationId } = props
  const [deviceData, setDeviceData] = useState(null)
  const [saveLoading, setSaveLoading] = useState(false)
  const [info, setInfo] = useState([]) // 初始数据

  // 获取下拉数据
  useEffect(() => {
    Promise.all([
      api.getRepairDetail({ id: operationId }), // 获取维修初始数据
    ]).then(([data]) => {
      setInfo(data || [])
    }).catch(error => {
      console.error("数据加载失败:", error)
    })
  }, [])

  // 获取维修完成详情（编辑模式）
  useEffect(() => {
    if (repairFinishType === 1 && operationId) {
      api.getRepairDetail({ id: operationId }).then(data => {
        const { sparePartsCost, technicalCost, totalMaintenanceCost, maintenanceNotes } = data
        setDeviceData({
          sparePartsCost,
          technicalCost,
          totalMaintenanceCost,
          maintenanceNotes
        })

      })
    }
  }, [operationId, repairFinishType])

  // 填充表单数据
  useEffect(() => {
    if (deviceData) {
      setFieldsValue(deviceData)
    }
  }, [deviceData])

  // 提交表单
  const handleSubmit = (e) => {
    e.preventDefault()
    validateFields((err, values) => {
      if (!err) {
        const formattedValues = {
          ...values,
        }
        if (operationId) {
          formattedValues.id = operationId
        }

        setSaveLoading(true)
        api.deviceRepairFinishApply(formattedValues).then(() => {
          message.success(`设备维修完成${repairFinishType === 1 ? '编辑' : '申请提交'}成功`)
          setSaveLoading(false)
          getList()
          setRepairFinishVisible(false)
        }).catch(() => setSaveLoading(false))
      }
    })
  }

  // 重置表单
  const handleReset = () => {
    resetFields()
    setRepairFinishVisible(false)
  }

  const formItemLayout = {
    labelCol: { xs: { span: 24 }, sm: { span: 6 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }
  }

  // **去掉边框的样式**
  const inputStyle = { border: 'none', boxShadow: 'none', backgroundColor: 'transparent', padding: 0 }


  return (
    <div className='modulePublic-Apply-container'>
      <Form onSubmit={handleSubmit} {...formItemLayout}>
        <FormItem style={{ marginBottom: 4 }} label="维修单号">
          <Input
            value={info?.repairOrderNumber || '-'}
            style={inputStyle}
            readOnly
          />
        </FormItem>
        <FormItem style={{ marginBottom: 4 }} label="设备名称">
          <Input
            value={info?.deviceName || '-'}
            style={inputStyle}
            readOnly
          />
        </FormItem>
        <FormItem style={{ marginBottom: 4 }} label="SN编码">
          <Input
            value={info?.snCode || '-'}
            style={inputStyle}
            readOnly
          />
        </FormItem>
        <FormItem style={{ marginBottom: 4 }} label="资产编码">
          <Input
            value={info?.assetCode || '-'}
            style={inputStyle}
            readOnly
          />
        </FormItem>
        <FormItem style={{ marginBottom: 4 }} label="申请日期">
          <Input
            value={info?.applicationDate || '-'}
            style={inputStyle}
            readOnly
          />
        </FormItem>
        <FormItem style={{ marginBottom: 4 }} label="维修公司">
          <Input
            value={info?.maintenanceCompany || '-'}
            style={inputStyle}
            readOnly
          />
        </FormItem>
        <FormItem style={{ marginBottom: 4 }} label="维修经办人">
          <Input
            value={info?.maintenanceHandler || '-'}
            style={inputStyle}
            readOnly
          />
        </FormItem>
        <FormItem style={{ marginBottom: 4 }} label="故障说明">
          <Input
            value={info?.faultDescription || '-'}
            style={inputStyle}
            readOnly
          />
        </FormItem>
        <FormItem style={{ marginBottom: 4 }} label="配件费用 (元)">
          {getFieldDecorator('sparePartsCost', { rules: [{ required: true, message: '请输入配件费用' }] })(
            <InputNumber placeholder="请填写数字" min={0} style={{ width: '100%' }} />
          )}
        </FormItem>
        <FormItem style={{ marginBottom: 4 }} label="技术费用 (元)">
          {getFieldDecorator('technicalCost', { rules: [{ required: true, message: '请输入技术费用' }] })(
            <InputNumber placeholder="请填写数字" min={0} style={{ width: '100%' }} />
          )}
        </FormItem>
        <FormItem style={{ marginBottom: 4 }} label="维修总费用 (元)">
          {getFieldDecorator('totalMaintenanceCost', { rules: [{ required: true, message: '请输入维修总费用' }] })(
            <InputNumber placeholder="请填写数字" min={0} style={{ width: '100%' }} />
          )}
        </FormItem>

        <FormItem style={{ marginBottom: 4 }} label="维修说明">
          {getFieldDecorator('maintenanceNotes')(
            <TextArea placeholder="请输入维修说明信息" />
          )}
        </FormItem>
        <FormItem wrapperCol={{ span: 24, style: { textAlign: 'center' } }}>
          <Button onClick={handleReset} style={{ marginRight: 8 }}>取消</Button>
          <Button loading={saveLoading} type="primary" htmlType="submit">确认</Button>
        </FormItem>
      </Form>
    </div>
  )
}

export default Form.create()(RepairFinishForm)
