import { configureStore } from 'redux-starter-kit'
import rootReducer from './global/reducer'

export default function configureAppStore(preloadedState) {
  const store = configureStore({
    reducer: rootReducer
  })

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./global/reducer', () => {
      const newRootReducer = require('./global/reducer').default
      store.replaceReducer(newRootReducer)
    })
  }

  return store
}