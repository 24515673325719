/**
 * 模块名称: 工资管理
 * @author xuzhongyuan@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Level from './Level'
import Salary from './Salary'
import './assets/style.scss'

const Wage = ({ match, history }) => {
  return (
    <Switch>
      <Route path={match.path + '/level'} component={Level} />
      <Route path={match.path + '/salary'} component={Salary} />
      <Route component={Level} />
    </Switch>
  )
}

export default Wage