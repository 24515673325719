/**
 * 首层路由
 * 需要布局组件的页面，使用wrapLayout
 */

import React from 'react'
// import { Switch, Route, Redirect } from 'react-router-dom'
import { Switch, Route } from 'react-router-dom'
import Layout from './components/Layout'
import { parseSearch } from './utils'

import Login from './pages/Login'
import ForgetPassWord from './pages/ForgetPassWord'
import Index from './pages/Index'
// import Test from './pages/Test'
import NoMatch from './pages/404'
import Corp from './pages/Corp'
import Manpower from './pages/Manpower'
import ImportDown from './pages/ImportDown'
import DailyWork from './pages/DailyWork'
import AuthManagement from './pages/AuthManagement'
import SetSystem from './pages/SetSystem'
import ShowPassword from './pages/ForgetPassWord/ShowPass'
import Message from './pages/Message'
import Schedule from './pages/Schedule'
import Document from './pages/Document'
import Regulation from './pages/Regulation'
import Notice from './pages/Notice'
import Helplist from './pages/Helplist'
import Procedure from './pages/Procedure'
import ProcedureSetting from './pages/Procedure/Setting'
import Attendance from './pages/Attendance'
import Wage from './pages/Wage'
import Funds from './pages/Funds'
import Socialfund from './pages/Socialfund'
import Journal from './pages/Management'
import Train from './pages/Train'
import AuthorizationManage from './pages/AuthorizationManage'
import RefundManage from './pages/RefundManage'
import AgentTransfer from "./pages/AgentTransfer"
import AgentIntegral from './pages/AgentIntegral'
import AgentName from './pages/AgentName'
import FundMoneyBack from './pages/FundMoneyBack'
import FundMoneyClaim from './pages/FundMoneyClaim'
import FundMoneyTransmit from './pages/FundMoneyTransmit'
import Account from './pages/Account'
import Csr from './pages/Customer'
import NameChange from './pages/NameChangeAudits'
import Config from './pages/ConfigMsg'
import Protocol from './pages/Protocol'
import OrderDirect from './pages/OrderDirect'
import OrderAgent from './pages/OrderAgent'
import PayBackDetail from './pages/Order'
import Agent from './pages/Agent'
import AgentNew from './pages/AgentNew'
import AgentManufacturer from './pages/AgentManufacturer'
import PerformanceAdjustment from './pages/PerformanceAdjustment'
import Kingdee from './pages/Kingdee'
import ToBeSettledList from './pages/ToBeSettledList'
import Asset from './pages/Asset'
import Supplie from './pages/Supplie'
import SettledList from './pages/SettledList'
import Achievement from './pages/Achievement'
import CorpAssets from './pages/CorpAssets'
import MyAssets from './pages/MyAssets'
import Analysis from './pages/Analysis'
import CorpAccount from './pages/CorpAccount'
import VrDevice from './pages/VrDevice'

const wrapLayout = (Comp, props) => {
  const search = parseSearch(props.location.search)
  if (search.deviceId || search.deviceId == 0) global.$deviceId = search.deviceId
  return <Layout {...props}><Comp {...props} /></Layout>
}

export default () => (
  <Switch>
    <Route exact path="/" component={Login} />
    <Route path="/forgetPassWord" component={ForgetPassWord} />
    <Route path="/setPassword" component={ShowPassword} />
    <Route path="/index" render={props => wrapLayout(Index, props)} />
    {/* <Route path="/test" component={Test} /> */}
    <Route path="/corp" render={props => wrapLayout(Corp, props)} />
    <Route path="/hr" render={props => wrapLayout(Manpower, props)} />
    <Route path="/importDown" render={props => wrapLayout(ImportDown, props)} />
    <Route path="/office" render={props => wrapLayout(DailyWork, props)} />
    <Route path="/setSystem" render={props => wrapLayout(SetSystem, props)} />
    <Route path="/authManagement" render={props => wrapLayout(AuthManagement, props)} />
    <Route path="/train" render={props => wrapLayout(Train, props)} />
    <Route path="/message" render={props => wrapLayout(Message, props)} />
    <Route path="/schedule" render={props => wrapLayout(Schedule, props)} />
    <Route path="/document" render={props => wrapLayout(Document, props)} />
    <Route path="/regulation" render={props => wrapLayout(Regulation, props)} />
    <Route path="/notice" render={props => wrapLayout(Notice, props)} />
    <Route path="/helplist" render={props => wrapLayout(Helplist, props)} />
    <Route path="/procedure" render={props => wrapLayout(Procedure, props)} />
    <Route path="/attendance" render={props => wrapLayout(Attendance, props)} />
    <Route path="/procedureSetting" component={ProcedureSetting} />
    <Route path="/wage" render={props => wrapLayout(Wage, props)} />
    <Route path="/socialfund" render={props => wrapLayout(Socialfund, props)} />
    <Route path="/journal" render={props => wrapLayout(Journal, props)} />
    <Route path="/fund/authorizationManage" render={props => wrapLayout(AuthorizationManage, props)} />
    <Route path="/fund/refundManage" render={props => wrapLayout(RefundManage, props)} />
    <Route path="/fund/moneyBack" render={props => wrapLayout(FundMoneyBack, props)} />
    <Route path="/fund/moneyClaim" render={props => wrapLayout(FundMoneyClaim, props)} />
    <Route path="/fund/moneyTransmit" render={props => wrapLayout(FundMoneyTransmit, props)} />
    <Route path="/fund" render={props => wrapLayout(Funds, props)} />
    <Route path="/account" render={props => wrapLayout(Account, props)} />
    <Route path="/csr/nameChangeAudit" render={props => wrapLayout(NameChange, props)} />
    <Route path="/csr" render={props => wrapLayout(Csr, props)} />
    <Route path="/config" render={props => wrapLayout(Config, props)} />
    <Route path="/protocol" render={props => wrapLayout(Protocol, props)} />
    <Route path="/orderDirect" render={props => wrapLayout(OrderDirect, props)} />
    <Route path="/orderAgent" render={props => wrapLayout(OrderAgent, props)} />
    <Route path="/Order/PayBackDetail" render={props => wrapLayout(PayBackDetail, props)} />
    <Route path="/agent/agentTransfer" render={props => wrapLayout(AgentTransfer, props)} />
    <Route path="/agent/integralManage" render={props => wrapLayout(AgentIntegral, props)} />
    <Route path="/agent/nameManage" render={props => wrapLayout(AgentName, props)} />
    <Route path="/agent/manufacturer" render={props => wrapLayout(AgentManufacturer, props)} />
    <Route path="/agent/agentNew" render={props => wrapLayout(AgentNew, props)} />
    <Route path="/agent/manage" render={props => wrapLayout(Agent, props)} />
    <Route path="/performance" render={props => wrapLayout(PerformanceAdjustment, props)} />
    <Route path="/kingdee" render={props => wrapLayout(Kingdee, props)} />
    <Route path="/toBeSettledList" render={props => wrapLayout(ToBeSettledList, props)} />
    <Route path="/asset" render={props => wrapLayout(Asset, props)} />
    <Route path="/supplie" render={props => wrapLayout(Supplie, props)} />
    <Route path="/settledList" render={props => wrapLayout(SettledList, props)} />
    <Route path="/ach" render={props => wrapLayout(Achievement, props)} />
    <Route path="/corpAssets" render={props => wrapLayout(CorpAssets, props)} />
    <Route path="/myAssets" render={props => wrapLayout(MyAssets, props)} />
    <Route path="/analysis" render={props => wrapLayout(Analysis, props)} />
    <Route path="/corpAccount" render={props => wrapLayout(CorpAccount, props)} />
    <Route path="/vr" render={props => wrapLayout(VrDevice, props)} />
    <Route component={NoMatch} />
  </Switch>
)