/**
 * 模块名称: 设备管理模块
 * @author zhuyan@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Stock from './Stock'
import StockDetail from './StockDetail'
const StockIndex = ({ match, history }) => {

  return (
    <div>
      <Switch>
        <Route path={match.path + '/detail'} component={StockDetail} />
        <Route component={Stock} />
      </Switch>
    </div>
  )
}

export default StockIndex