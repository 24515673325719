/**
 * 模块名称: 待遇级别管理
 * @author xuzhongyuan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Alert,
  Button,
  Input,
  Select,
  message,
  Icon,
  Row,
  Col,
  DatePicker
} from 'antd'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import CorpFilter from '@/components/CorpFilter'
import Import from '@/components/Import'
import Export from '@/components/Export'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
let pageSize = 10
let currentPage = 1

let filter = {
  companyId: '',
  employeeNo: '',
  employeeName: '',
  jobStation: '',
  isFormal: '',
  departmentId: '',
  jobPosition: '',
  leaderId: '',
  entryDate: '',
  trueName: '',
  attendanceNum: '',
}

let selectedIds = ''

const Level = (props) => {
  const ImportRef = useRef()
  const productsRef = useRef()
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [authList, setAuthList] = useState([])
  const [selectedCount, setSelectedCount] = useState(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [expand, setExpand] = useState(false)
  const [depList, setDepList] = useState([])
  const [selectData, setSelectData] = useState({
    staff_is_formal: [],
    staff_job_position: [],
    staff_job_station: []
  })
  const [showExport, setShowExport] = useState(false)
  const [showImport, setShowImport] = useState(false)
  const search = parseSearch(location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.pageSize ? +search.pageSize : 10
    filter = {
      companyId: '',
      employeeNo: '',
      employeeName: '',
      jobStation: '',
      isFormal: '',
      departmentId: '',
      jobPosition: '',
      leaderId: '',
      entryDate: '',
      trueName: '',
      attendanceNum: '',
    }
    getList()
    api.getPageAuth().then(list => setAuthList(list))
    api.getCommon({ type: 'staff_is_formal,staff_job_station,staff_job_position' }).then(data => {
      setSelectData(data)
    })
  }, [])

  const getList = () => {
    setLoading(true)
    api.getWageLevelList({ ...filter, limit: pageSize, page: currentPage })
      .then(data => {
        setLoading(false)
        setList(data.list)
        setCount(data.count)
      })
      .catch(() => setLoading(false))
  }

  const getDepartmentList = () => {
    api.getSelectDepartmentList({ companyId: filter.companyId }).then(data => {
      setDepList(data.list)
    })
  }

  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      // console.log(vals)
      if (!err) {
        if (vals.entryDate) {
          vals.entryDate = vals.entryDate.format('YYYY-MM-DD')
        }
        filter = { ...filter, ...vals }
        currentPage = 1
        history.replace(match.path)
        getList()
      }
    })
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}`)
    getList()
  }

  const onChangeCorp = (data) => {
    setDepList([])
    setSelectedCount(0)
    setSelectedRowKeys([])
    setFieldsValue({'departmentId': undefined})
    filter.departmentId = ''
    filter.companyId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
      setSelectedCount(selectedRowKeys.length)
      setSelectedRowKeys(selectedRowKeys)
      selectedIds = selectedRowKeys
    },
    selectedRowKeys: selectedRowKeys
  }

  const onToggle = () => {
    setExpand(!expand)
  }

  const onReset = () => {
    resetFields()
    filter = {
      companyId: filter.companyId,
      employeeNo: '',
      employeeName: '',
      jobStation: '',
      isFormal: '',
      departmentId: '',
      jobPosition: '',
      leaderId: '',
      entryDate: '',
      trueName: '',
      attendanceNum: '',
    }
    currentPage = 1
    history.replace(match.path)
    getList()
  }

  // 导入确定
  const onConfirmImport = (fileList) => {
    if (fileList.length > 0) {
      if (fileList[0].status !== 'removed') {
        api.setwageLevelImport({
          uploadUrl: fileList[0].url
        }).then(res => {
          getList()
          message.success('导入成功')
          ImportRef.current.onLoading()
          setShowImport(false)
        }).catch(res => {
          // console.log('导入', res)
          ImportRef.current.onLoading()
        })
      } else {
        message.error('请导入模板')
      }
    } else {
      message.error('请导入模板')
    }
  }

  const onShowImportDialog = () => {
    ImportRef.current.onRemoved()
    setShowImport(true)
  }

  const onShowExportDialog = () => {
    setShowExport(true)
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  return (
    <>
      <CorpFilter onChange={onChangeCorp} />
      <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo" style={{paddingBottom: 10}}>
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={6}>
                <FormItem label="员工编号" labelCol={{ span: 8 }}>
                  {getFieldDecorator('employeeNo', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="公司用名" labelCol={{ span: 8 }}>
                  {getFieldDecorator('employeeName', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              {/* <Col span={6}>
                <FormItem label="员工类型" labelCol={{ span: 8 }}>
                  {getFieldDecorator('jobStation')(
                    <Select placeholder="请选择">
                      {selectData.staff_job_station.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col> */}
              <Col span={6}>
                <FormItem label="人员类型" labelCol={{ span: 8 }}>
                  {getFieldDecorator('isFormal')(
                    <Select placeholder="请选择">
                      {selectData.staff_is_formal.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={6}>
                <FormItem label="所属部门" labelCol={{ span: 8 }}>
                  {getFieldDecorator('departmentId')(
                    <Select 
                      placeholder="请选择"
                      showSearch
                      optionFilterProp="children"
                      onDropdownVisibleChange={bool => bool && getDepartmentList()}
                    >
                      {depList.map(v => {
                        return !filter.companyId.length ? <Option title={v.name + '（' + v.companyName + '）'} key={v.id}>{v.name}（{v.companyName}）</Option> : <Option title={v.name} key={v.id}>{v.name}</Option>
                      })}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="职级" labelCol={{ span: 8 }}>
                  {getFieldDecorator('jobPosition')(
                    <Select placeholder="请选择">
                      {selectData.staff_job_position.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="直属上级" labelCol={{ span: 8 }}>
                  {getFieldDecorator('leaderId', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="到职日期" labelCol={{ span: 8 }}>
                  {getFieldDecorator('entryDate')(<DatePicker style={{ width: '100%' }} />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="真实姓名" labelCol={{ span: 8 }}>
                  {getFieldDecorator('trueName', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="考勤号" labelCol={{ span: 8 }}>
                  {getFieldDecorator('attendanceNum', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
            </Row>
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle} style={{cursor: 'pointer', color: 'rgb(24, 144, 255)'}}>
            <span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
          </span>
        </div>
        <div className="search-btns">
          <Button onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
        </div>
      </Form>
      <div className="line"></div>
      <div className="add-wrap">
        <Auth auths={authList} code="operate">
          {/* <Upload {...uploadProps}> */}
          <Button type="primary" icon="download" onClick={onShowImportDialog}>导入</Button>
          &nbsp;
          {/* </Upload> */}
          <Button type="primary" icon="upload" onClick={onShowExportDialog}>导出</Button>
        </Auth>
      </div>
      <Alert className="corp-count" message={<>
          <span>已选择 <span style={{color: '#168FFF'}}>{selectedCount}</span> 项</span>
          <span style={{ marginLeft: 20 }}>总共{count}条数据</span>
        </>} type="info" showIcon />
      <Table
        size="middle"
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 2200 }}
        onChange={onChangeTable}
        rowSelection={rowSelection}
      >
        <Column title="员工编号" dataIndex="employeeNo" fixed="left" width={150} />
        <Column title="公司用名" dataIndex="employeeName" fixed="left" width={100} />
        <Column title="所属公司" dataIndex="companyName" width={150} />
        <Column title="真实姓名" dataIndex="trueName" />
        <Column title="考勤号" dataIndex="attendanceNum" />
        <Column title="公司邮箱" dataIndex="workEmail" width={210} />
        {/* <Column title="员工类型" dataIndex="jobStation" /> */}
        <Column title="人员类型" dataIndex="isFormal" />
        <Column title="所属部门" dataIndex="departmentName" />
        <Column title="直属上级" dataIndex="leaderName" />
        <Column title="岗位级别" dataIndex="stationLevel" />
        <Column title="职级" dataIndex="jobPosition" />
        <Column title="到职日期" key="entryDate" width={120} render={text => text.entryDate ? text.entryDate.split(' ')[0] : '-'} />
        <Column title="员工状态" dataIndex="isOnJob" />
        <Column title="待遇级别" dataIndex="treatmentLevel" />
        <Column title="任务基数" dataIndex="taskBase" />
        <Column title="底薪" dataIndex="baseSalary" />
        <Column title="岗位工资" dataIndex="stationWage" />
        <Column title="绩效" dataIndex="achievements" />
        <Column title="项目奖金" dataIndex="projectBonus" />
        <Column title="补助" dataIndex="subsidy" />
      </Table>
      <Import
        onSearch={onConfirmImport}
        visibleFile={showImport}
        onCloseImport={() => setShowImport(false)}
        ImportTemplate="/api/File/DownTemplate?url=/templates/wage_level.xlsx&name=待遇级别管理导入模板"
        ImportRef={ImportRef}
      />
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }}
        tplUrl="getWageLevelExportTpl"
        fieldsUrl="getWageLevelTplItems"
        saveUrl="saveWageLevelTpl"
        exportUrl="exportWageLevel"
        method="getlevellist"
        parame={{ ExportTyp: 'level', id: selectedIds, ...filter }}
        cRef={productsRef}
      />
    </>
  )
}

export default Form.create()(Level)