
/**
 * 模块名称: 设备历程
 * @author zhuyan@372163.com
 */
import React, { useState, useEffect, useMemo, useRef } from 'react'
import api from '@/api'
import HeaderSearch from '@/components/HeaderSearch'
import {
  Table,
  Form,
  Input,
  Select,
  Spin,
  DatePicker,
} from 'antd'
import TypeDetail from '../module/TypeDetail'

const pageIndex = 1
const perPage = 10
let searchParam = {
  page: pageIndex,
  limit: perPage,
}

const items = [
  { id: 1, name: '购买' },
  { id: 2, name: '借出' },
  { id: 3, name: '归还' },
  { id: 4, name: '保修' },
  { id: 5, name: '报废' },
]
const { Option } = Select
const { RangePicker } = DatePicker

const StockDetail = (props) => {
  const { match, location, history } = props
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(pageIndex)
  const [limit, setPageSize] = useState(perPage)
  const [total, setTotal] = useState(0)
  const [dataSource, setDataSource] = useState([])
  const [queryData, setQueryData] = useState({})
  const { id, deviceName, snCode, assetCode, deviceDescription } = location.state.rowInfo
  const [modalType, setModalType] = useState(null)
  const [modalDataId, setModalDataId] = useState(null)

  useEffect(() => {
    document.documentElement.scrollTop = 0
    resetParams()
    onSelectAll()
    getList()
  }, [])

  const resetParams = () => {
    searchParam = {
      page: pageIndex,
      limit: perPage,
    }
    setPageSize(perPage)
  }

  const onSelectAll = () => {
    api.getDeviceQueryData().then(res => {
      setQueryData(res)
    })
  }

  const getList = values => {
    if (values) {
      const { page, limit } = searchParam
      searchParam = { page, limit, ...values }
    }
    let params = { ...searchParam }
    params.deviceId = id
    setLoading(true)
    api.getVrDeviceProcessList(params).then(data => {
      setPage(searchParam.page)
      setPageSize(searchParam.limit)
      setTotal(data.count)
      setDataSource(data.list)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  const onChangeTable = (pagination, filters, sorter) => {
    searchParam.page = pagination.current
    searchParam.limit = pagination.pageSize
    getList()
  }

  const handleOpenModal = (data) => {
    setModalType(data.operationType)
    setModalDataId(data.operationId)
  }

  const handleCloseModal = () => {
    setModalType(null)
    setModalDataId(null)
  }

  const cols = [{
    title: '序号',
    dataIndex: 'id',
    fixed: 'left',
    align: 'center',
    render: (text, record, index) => total - ((searchParam.page - 1) * searchParam.limit + index)
  }, {
    title: '设备操作',
    dataIndex: 'operationTypeName',
    align: 'center',
  }, {
    title: '操作人',
    dataIndex: 'operatorName',
    align: 'center',
  }, {
    title: '操作部门',
    dataIndex: 'departmentName',
    align: 'center',
  }, {
    title: '操作时间',
    dataIndex: 'operationTime',
    align: 'center',
  }, , {
    title: '操作时长（天）',
    dataIndex: 'duration',
    align: 'center',
  }, {
    title: '关联项目',
    dataIndex: 'relatedProject',
    align: 'center',
    render: (text, record) => {
      return <div style={{ wordWrap: 'break-word', wordBreak: 'break-word', maxWidth: '240px' }}>
        {text || '-'}
      </div>
    }
  }, {
    title: '运营方式',
    dataIndex: 'operationMode',
    align: 'center',
    render: (text) => text || '-'
  }, {
    title: '操作',
    dataIndex: 'actions',
    align: 'center',
    width: 100,
    fixed: 'right',
    render: (text, record) => (
      <>
        {
          <a onClick={() => handleOpenModal(record)}>查看</a>
        }
      </>
    )
  }]

  const searchItems = useMemo(() => {
    let searchTempArr = [
      {
        label: '操作人',
        name: 'operatorName',
        component: <Input placeholder="请输入操作人" />,
      },
      {
        label: '操作部门',
        name: 'departmentName',
        component: <Input placeholder="请输入操作部门" />,
      },
      {
        label: '关联项目',
        name: 'relatedProject',
        component: (
          <Select placeholder="全部"
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
          >
            <Option value="select-all">全部</Option>
            {
              queryData.projectNameList?.map((v, i) => {
                return <Option value={v.id} key={i}>{v.name}</Option>
              })
            }
          </Select>
        ),
      },
      {
        label: '运营方式',
        name: 'operationMode',
        component: (
          <Select placeholder="全部"
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
          >
            <Option value="select-all">全部</Option>
            {
              queryData.operationModeList?.map((v, i) => {
                return <Option value={v.id} key={i}>{v.name}</Option>
              })
            }
          </Select>
        ),
      },
      {
        label: '设备操作',
        name: 'operationType',
        component: (
          <Select placeholder="全部"
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
          >
            <Option value="select-all">全部</Option>
            {
              items?.map((v, i) => {
                return <Option value={v.id} key={i}>{v.name}</Option>
              })
            }
          </Select>
        ),
      },
      {
        label: '借出日期',
        name: 'rangeSE:borrowDate',
        component: <RangePicker style={{ width: '100%' }} />,
      },
      {
        label: '归还日期',
        name: 'rangeSE:returnDate',
        component: <RangePicker style={{ width: '100%' }} />,
      },
    ]

    return { search: searchTempArr }
  }, [queryData])

  return (
    <div className="vrdevice-stock-container">
      <Spin spinning={loading} tip="Loading...">
        <HeaderSearch
          {...searchItems}
          getList={getList}
          resetParams={resetParams}
          hasShowAll={false}
        ></HeaderSearch>
        <div className='line'></div>
        <div className='line'></div>
        <div className="device-info-box">
          <p>设备名称：<b>{deviceName}</b> &nbsp; &nbsp;SN编码：<b>{snCode}</b> &nbsp;&nbsp; 资产编码：<b>{assetCode}</b></p>
          <p>设备详情：<b>{deviceDescription}</b></p>
        </div>
        <Table
          size="small"
          columns={cols}
          rowKey="id"
          dataSource={dataSource}
          scroll={{ x: 'max-content' }}
          // bordered
          pagination={{
            defaultCurrent: pageIndex,
            pageSize: limit,
            total: total,
            showSizeChanger: true,
            showQuickJumper: true,
            current: page,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          onChange={onChangeTable}
        ></Table>
        <TypeDetail type={modalType} onClose={handleCloseModal} dataId={modalDataId} />
      </Spin>
    </div>
  )
}

export default Form.create()(StockDetail)
