/**
 * 模块名称: 设备归还申请模块
 * @author zhuyan@372163.com
 */
import React, { useEffect, useState } from 'react'
import { Form, Input, InputNumber, DatePicker, Button, message, Icon } from 'antd'
import moment from 'moment'
import api from '@/api'
import '../assets/module.scss'

const { TextArea } = Input
const FormItem = Form.Item

const ReturnDeviceForm = (props) => {
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const { setReturnVisible, getList, returnType, editId, selectedRowId, setSelectedRows, setSelectedRowKeys } = props
  const [deviceData, setDeviceData] = useState(null)
  const [saveLoading, setSaveLoading] = useState(false)
  const [snCodes, setSnCodes] = useState([]) // 设备SN列表

  // 根据id获取snCodes
  useEffect(() => {
    if (selectedRowId && selectedRowId.length > 0) {
      api.getSnCodeByDeviceId({ deviceIds: selectedRowId.join(','), type: 2  }).then(data => { // 获取snCode编码
        setSnCodes(data || [])
      }).catch(error => {
      })
    }
  }, [])

  // 获取设备归还详情（编辑模式）
  useEffect(() => {
    if (returnType === 1 && editId) {
      api.getReturnDetail({ id: editId }).then(data => {
        const { count, departmentName, userName, returnDate, returnDescription, snCodes } = data
        setDeviceData({
          count,
          departmentName,
          userName,
          returnDescription,
          returnDate: returnDate ? moment(returnDate) : null,
        })
        setSnCodes(snCodes ? snCodes.split(',') : [])
      })
    }
  }, [editId, returnType])

  // 填充表单数据
  useEffect(() => {
    if (deviceData) {
      setFieldsValue(deviceData)
    }
  }, [deviceData])


  // 删除 SN 编码
  const handleRemoveSn = (sn) => {
    setSnCodes(snCodes.filter(code => code !== sn))
  }

  // 提交表单
  const handleSubmit = (e) => {
    e.preventDefault()
    validateFields((err, values) => {
      if (!err) {
        if (snCodes && snCodes.length > values.count) {
          message.success('设备SN编码中设备数量需小于等于归还设备数量')
          return
        }
        const formattedValues = {
          ...values,
          returnDate: values.returnDate ? values.returnDate.format('YYYY-MM-DD') : null,
          snCodes: snCodes.join(', ')
        }
        if (editId) {
          formattedValues.id = editId
        }

        setSaveLoading(true)
        api.deviceReturnApply(formattedValues).then(() => {
          message.success(`归还${returnType === 1 ? '编辑' : '申请提交'}成功`)
          setSaveLoading(false)
          setReturnVisible(false)
          getList()
          if (returnType === 0) {
            setSelectedRows([])
            setSelectedRowKeys([])
          }
        }).catch(() => setSaveLoading(false))
      }
    })
  }

  // 重置表单
  const handleReset = () => {
    resetFields()
    setReturnVisible(false)
  }

  const formItemLayout = {
    labelCol: { xs: { span: 24 }, sm: { span: 6 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }
  }

  return (
    <div className='modulePublic-Apply-container'>
      <Form onSubmit={handleSubmit} {...formItemLayout}>
        <FormItem style={{ marginBottom: 4 }} label="归还设备数量">
          {getFieldDecorator('count', { rules: [{ required: true, message: '请输入归还设备数量' }] })(
            <InputNumber placeholder="请输入归还设备数量" min={1} style={{ width: '100%' }} />
          )}
        </FormItem>
        <FormItem style={{ marginBottom: 4 }} label="归还人部门">
          {getFieldDecorator('departmentName', { rules: [{ required: true, message: '请输入设备归还人部门' }] })(
            <Input placeholder="请输入设备归还人部门名称" />
          )}
        </FormItem>  
        <FormItem style={{ marginBottom: 4 }} label="归还人">
          {getFieldDecorator('userName', { rules: [{ required: true, message: '请输入设备归还人' }] })(
            <Input placeholder="请输入设备归还人姓名" />
          )}
        </FormItem>
        <FormItem style={{ marginBottom: 4 }} label="归还日期">
          {getFieldDecorator('returnDate', { rules: [{ required: true, message: '请选择归还日期' }] })(
            <DatePicker placeholder="请选择归还日期" style={{ width: '100%' }} />
          )}
        </FormItem>

        <FormItem style={{ marginBottom: 4 }} label="归还说明">
          {getFieldDecorator('returnDescription')(
            <TextArea placeholder="请输入归还说明" />
          )}
        </FormItem>

        <FormItem label="设备 SN 编码" style={{ marginBottom: 4 }} className="custom-sn-form-item">
          {getFieldDecorator('snCodes')(
            <div className="sn-container">
              <div className="sn-box">
                {snCodes && snCodes.map((sn, index) => (
                  <div key={index} className="sn-item">
                    <span className="sn-text">{sn}</span>
                    <Icon type="close" className="sn-close" onClick={() => handleRemoveSn(sn)} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </FormItem>

        <FormItem wrapperCol={{ span: 24, style: { textAlign: 'center' } }}>
          <Button onClick={handleReset} style={{ marginRight: 8 }}>取消</Button>
          <Button loading={saveLoading} type="primary" htmlType="submit">确认</Button>
        </FormItem>
      </Form>
    </div>
  )
}

export default Form.create()(ReturnDeviceForm)
