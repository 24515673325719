
/**
 * 模块名称: 添加/编辑设备管理模块
 * @author zhuyan@372163.com
 */
import React, { useEffect, useState } from 'react'
import { Form, Input, InputNumber, Select, DatePicker, Button, message, Row, Col, Spin } from 'antd'
import moment from 'moment'
import api from '@/api'

const { Option } = Select
const { TextArea } = Input
const FormItem = Form.Item

const AddDevice = (props) => {
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const { seteditVisible, getList, addType, editId, statusType } = props
  const [allData, setAllData] = useState(null)
  const [deviceData, setDeviceData] = useState(null)
  const [saveLoading, setSaveLoading] = useState(false)
  const [accountList, setAccountList] = useState([]) // 公司主体数据
  const [staffList, setStaffList] = useState([]) // 采购员
  const [departmentList, setDepartmentList] = useState([]) // 部门
  const [userList, setUserList] = useState([]) // 申请人
  const [selectedDepartment, setSelectedDepartment] = useState(null) // 当前选中的部门
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    Promise.all([
      api.getCorp({ limit: global.paramsLimit }),
      api.getAllStaffSimpleList({ limit: global.paramsLimit, isContailDel: 2 }),
      api.getAllDepartmentList({ limit: global.paramsLimit, isContailDel: 2 })
    ]).then(([corpData, staffData, departmentData]) => {
      setAccountList(corpData.list || [])
      setStaffList(staffData || [])
      setDepartmentList(departmentData || [])
    }).catch(error => {
      console.error("数据加载失败:", error)
    })
  }, [])

  useEffect(() => {
    if (addType === 1 && editId) {
      setLoading(true)
      api.getDeviceDetail({ deviceId: editId }).then(data => {
        setAllData(data)
        const { companyId, departmentId, departmentLeaderId, applicantId, deviceBrand, deviceType,
          deviceName, snCode, assetCode, deviceSupplier, purchasePersonId, purchasePrice, otherPurchaseCost,
          purchaseQuantity, totalCost, warrantyPeriod, expectedUsefulLife, deviceDescription, registrationDate,
          storageDate, expectedReturnDate, actualReturnDate, projectName, operationMode } = data || {}

        const loanFields = statusType === '借出' ? {
          projectName,
          operationMode,
          expectedReturnDate: expectedReturnDate ? moment(expectedReturnDate) : null,
          actualReturnDate: actualReturnDate ? moment(actualReturnDate) : null,
        } : {}
        setDeviceData({
          companyId,
          departmentId,
          deviceBrand,
          deviceType,
          deviceName,
          snCode,
          assetCode,
          deviceSupplier,
          purchasePersonId,
          purchasePrice,
          otherPurchaseCost,
          purchaseQuantity,
          totalCost,
          warrantyPeriod,
          expectedUsefulLife,
          deviceDescription,   
          registrationDate: registrationDate ? moment(registrationDate) : null,
          storageDate: storageDate ? moment(storageDate) : null,       
          ...loanFields,
        })

        setSelectedDepartment(departmentId)  // 设置申请部门
        if (departmentId) {
          api.getStaffListByDepartmentId({ departmentId }).then(userData => {
            setUserList(userData || [])  // 设置申请人列表
            setFieldsValue({
              departmentLeaderId,
              applicantId
            })
          }).catch(error => console.error('获取申请人失败:', error))
        }
        setLoading(false)
      }).catch(error => {
        setLoading(false)
      })
    }
  }, [editId, addType])


  // 填充数据
  useEffect(() => {
    if (deviceData) {
      setFieldsValue(deviceData)
    }
  }, [deviceData])

  // 提交表单
  const handleSubmit = (e) => {
    e.preventDefault()
    validateFields((err, values) => {
      if (!err) {
        const formattedValues = {
          ...values,
          registrationDate: values.registrationDate ? values.registrationDate.format('YYYY-MM-DD') : null,
          storageDate: values.storageDate ? values.storageDate.format('YYYY-MM-DD') : null,
        }
        if (editId) {
          formattedValues.id = editId
          formattedValues.expectedReturnDate = values.expectedReturnDate ? values.expectedReturnDate.format('YYYY-MM-DD') : null
          formattedValues.actualReturnDate = values.actualReturnDate ? values.actualReturnDate.format('YYYY-MM-DD') : null
        }
        setSaveLoading(true)
        api.saveVrDevice(formattedValues).then(() => {
          message.success('保存成功')
          setSaveLoading(false)
          getList()
          seteditVisible(false)
        }).catch(() => setSaveLoading(false))
      }
    })
  }

  const handleReset = () => {
    resetFields()
    seteditVisible(false)
  }

  const formItemLayout = {
    labelCol: { xs: { span: 24 }, sm: { span: 7 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }
  }

  // **去掉边框的样式**
  const inputStyle = { border: 'none', boxShadow: 'none', backgroundColor: 'transparent', padding: 0 }

  // **选择申请部门后，获取申请人**
  const handleDepartmentChange = (departmentId) => {
    setSelectedDepartment(departmentId)
    setUserList([])
    if (departmentId) {
      api.getStaffListByDepartmentId({ departmentId }).then(userData => {
        setUserList(userData || [])
        setFieldsValue({
          departmentLeaderId: undefined,
          applicantId: undefined
        })
      }).catch(error => {
        console.error('获取申请人失败:', error)
      })
    }
  }

  return (
    <Spin spinning={loading}>
      <Form onSubmit={handleSubmit} {...formItemLayout}>
        <Row gutter={24}>
          <Col span={addType === 1 && statusType ==='借出' ? 12 : 24}>
            <FormItem style={{ marginBottom: 4 }} label="关联公司主体">
              {getFieldDecorator('companyId', { rules: [{ required: true, message: '请选择公司主体' }] })(
                <Select
                  showSearch={true}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="请选择公司主体"
                  dropdownMatchSelectWidth={false}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                >
                  {
                    accountList?.map((v, i) => {
                      return <Option value={v.id} key={i}>{v.name}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>

            <FormItem style={{ marginBottom: 4 }} label="申请部门">
              {getFieldDecorator('departmentId', { rules: [{ required: true, message: '请选择申请部门' }] })(
                <Select
                  showSearch={true}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="请选择申请部门"
                  dropdownMatchSelectWidth={false}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  onChange={handleDepartmentChange}
                >
                  {
                    departmentList?.map((v, i) => {
                      return <Option value={v.id} key={i}>{`${v.name}（${v.companyName}）`}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>

            <FormItem style={{ marginBottom: 4 }} label="申请部门负责人">
              {getFieldDecorator('departmentLeaderId', { rules: [{ required: true, message: '请选择负责人' }] })(
                <Select
                  placeholder="请选择负责人"
                  showSearch
                  optionFilterProp="children"
                  disabled={!selectedDepartment}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                >
                  {userList.map(v => (
                    <Option key={v.id} value={v.id}>{v.staffName}</Option>
                  ))}
                </Select>
              )}
            </FormItem>

            <FormItem style={{ marginBottom: 4 }} label="申请人">
              {getFieldDecorator('applicantId', { rules: [{ required: true, message: '请选择申请人' }] })(
                <Select
                  placeholder="请选择申请人"
                  showSearch
                  optionFilterProp="children"
                  disabled={!selectedDepartment}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                >
                  {userList.map(v => (
                    <Option key={v.id} value={v.id}>{v.staffName}</Option>
                  ))}
                </Select>
              )}
            </FormItem>

            <FormItem style={{ marginBottom: 4 }} label="设备品牌">
              {getFieldDecorator('deviceBrand', { rules: [{ required: true, message: '请输入设备品牌' }] })(
                <Input placeholder="请输入设备品牌" />
              )}
            </FormItem>

            <FormItem style={{ marginBottom: 4 }} label="设备类型">
              {getFieldDecorator('deviceType', { rules: [{ required: true, message: '请输入设备类型' }] })(
                <Input placeholder="请输入设备类型" />
              )}
            </FormItem>

            <FormItem style={{ marginBottom: 4 }} label="设备名称">
              {getFieldDecorator('deviceName', { rules: [{ required: true, message: '请输入设备名称' }] })(
                <Input placeholder="请输入设备名称" />
              )}
            </FormItem>
            <FormItem style={{ marginBottom: 4 }} label="SN 编码">
              {getFieldDecorator('snCode', { rules: [{ required: true, message: '请输入 SN 编码' }] })(
                <Input placeholder="请输入 SN 编码" />
              )}
            </FormItem>
            <FormItem style={{ marginBottom: 4 }} label="资产编码">
              {getFieldDecorator('assetCode', { rules: [{ required: true, message: '请输入资产编码' }] })(
                <Input placeholder="请输入资产编码" />
              )}
            </FormItem>
            <FormItem style={{ marginBottom: 4 }} label="设备供应商">
              {getFieldDecorator('deviceSupplier', { rules: [{ required: true, message: '请输入设备供应商' }] })(
                <Input placeholder="请输入设备供应商" />
              )}
            </FormItem>

            <FormItem style={{ marginBottom: 4 }} label="采购员">
              {getFieldDecorator('purchasePersonId', { rules: [{ required: true, message: '请选择采购员' }] })(
                <Select
                  showSearch={true}
                  optionFilterProp="children"
                  placeholder="请选择采购员"
                  dropdownMatchSelectWidth={false}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                >
                  {
                    staffList.map((v, i) => {
                      return <Option value={v.id} key={i}>{`${v.staffName}（${v.staffNo}）`}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
            <FormItem style={{ marginBottom: 4 }} label="登记日期">
              {getFieldDecorator('registrationDate', { rules: [{ required: true, message: '请选择登记日期' }] })(
                <DatePicker placeholder="请选择登记日期" style={{ width: '100%' }} />
              )}
            </FormItem>

            <FormItem style={{ marginBottom: 4 }} label="购入单价 (元)">
              {getFieldDecorator('purchasePrice', { rules: [{ required: true, message: '请输入购入单价' }] })(
                <InputNumber placeholder="请填写数字" min={0} style={{ width: '100%' }} />
              )}
            </FormItem>

            <FormItem style={{ marginBottom: 4 }} label="其它购置费用 (元)">
              {getFieldDecorator('otherPurchaseCost', { rules: [{ required: true, message: '请输入其它购置费用' }] })(
                <InputNumber placeholder="请填写数字，没有时填写0" min={0} style={{ width: '100%' }} />
              )}
            </FormItem>

            <FormItem style={{ marginBottom: 4 }} label="购入数量">
              {getFieldDecorator('purchaseQuantity', { rules: [{ required: true, message: '请输入购入数量' }] })(
                <InputNumber placeholder="请填写数字" min={0} style={{ width: '100%' }} />
              )}
            </FormItem>

            <FormItem style={{ marginBottom: 4 }} label="总计费用 (元)">
              {getFieldDecorator('totalCost', { rules: [{ required: true, message: '请输入总计费用' }] })(
                <InputNumber placeholder="请填写数字" min={0} style={{ width: '100%' }} />
              )}
            </FormItem>

            <FormItem style={{ marginBottom: 4 }} label="保修年限">
              {getFieldDecorator('warrantyPeriod', { rules: [{ required: true, message: '请输入保修年限' }] })(
                <InputNumber placeholder="请填写以年为单位的数字" style={{ width: '100%' }} />
              )}
            </FormItem>

            <FormItem style={{ marginBottom: 4 }} label="预计使用年限">
              {getFieldDecorator('expectedUsefulLife', { rules: [{ required: true, message: '请输入预计使用年限' }] })(
                <InputNumber placeholder="请填写以年为单位的数字" style={{ width: '100%' }} />
              )}
            </FormItem>

            <FormItem style={{ marginBottom: 4 }} label="入库日期">
              {getFieldDecorator('storageDate', { rules: [{ required: true, message: '请选择入库日期' }] })(
                <DatePicker placeholder="请选择入库日期" style={{ width: '100%' }} />
              )}
            </FormItem>

            <FormItem style={{ marginBottom: 4 }} label="设备说明">
              {getFieldDecorator('deviceDescription')(
                <TextArea placeholder="请输入设备说明" />
              )}
            </FormItem>
          </Col>
          {
            addType === 1 && statusType === '借出' && 
            <Col span={12}>
              <FormItem style={{ marginBottom: 4 }} label="使用部门">
                <Input
                  value={allData?.usageDepartmentName || '-'}
                  style={inputStyle}
                  readOnly
                />
              </FormItem>

              <FormItem style={{ marginBottom: 4 }} label="使用人">
                <Input
                  value={allData?.usagePersonName || '-'}
                  style={inputStyle}
                  readOnly
                />
              </FormItem>

              <FormItem style={{ marginBottom: 4 }} label="借出日期">
                <Input
                  value={allData?.loanDate || '-'}
                  style={inputStyle}
                  readOnly
                />
              </FormItem>
              <FormItem style={{ marginBottom: 4 }} label="关联项目">
                {getFieldDecorator('projectName')(<Input placeholder="请输入关联项目"/>)}
              </FormItem>

              <FormItem style={{ marginBottom: 4 }} label="运营方式">
                {getFieldDecorator('operationMode')(<Input placeholder="请输入运营方式" />)}
              </FormItem>

              <FormItem style={{ marginBottom: 4 }} label="预计归还日期">
                {getFieldDecorator('expectedReturnDate', { rules: [{ required: false, message: '请选择预计归还日期' }] })(
                  <DatePicker placeholder="请选择预计归还日期" style={{ width: '100%' }} />
                )}
              </FormItem>

              <FormItem style={{ marginBottom: 4 }} label="实际归还日期">
                {getFieldDecorator('actualReturnDate', { rules: [{ required: false, message: '请选择实际归还日期' }] })(
                  <DatePicker placeholder="请选择实际归还日期" style={{ width: '100%' }} />
                )}
              </FormItem>

            </Col>
          }
        </Row>
        <FormItem wrapperCol={{ span: 24, style: { textAlign: 'center' } }}>
          <Button onClick={handleReset} style={{ marginRight: 8 }}>取消</Button>
          <Button loading={saveLoading} type="primary" htmlType="submit">确认</Button>
        </FormItem>
      </Form>
    </Spin>
  )
}

export default Form.create()(AddDevice)
