/**
 * 模块名称: vr设备库存管理 => 历程
 * @author zhuyan@372163.com
 */
import React, { useEffect, useState } from 'react'
import api from '@/api'
import { Modal, Spin, message, Button, Input } from 'antd'
import '../assets/module.scss'

const DynamicModal = ({ type, onClose, dataId, modaName = '单', getList, auditData = null }) => {
  const [loading, setLoading] = useState(false)
  const [content, setContent] = useState(null)
  const [typeName, setTypeName] = useState(null)
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false)
  const [rejectReason, setRejectReason] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        let response
        switch (type) {
          case 1: // 购买
            response = await api.getDeviceDetail({ deviceId: dataId })
            setTypeName('设备购买单')
            break
          case 2: // 借出
            response = await api.getBorrowDetail({ id: dataId })
            setTypeName(`设备借出${modaName}`)
            break
          case 3: // 归还
            response = await api.getReturnDetail({ id: dataId })
            setTypeName(`设备归还${modaName}`)
            break
          case 4: // 维修
            response = await api.getRepairDetail({ id: dataId })
            setTypeName(`设备维修完成${auditData ? '审批' : '详情'}`)
            break
          case 5: // 报废
            response = await api.getDisposalDetail({ id: dataId })
            setTypeName('设备报废单')
            break
          default:
            throw new Error('未知的弹窗类型')
        }
        setContent(response)
      } catch (error) {
        // message.error('加载数据失败')
      } finally {
        setLoading(false)
      }
    }

    if (type) {
      fetchData()
    }
  }, [type, dataId])

  const renderContent = () => {
    if (!content) return null

    switch (type) {
      case 1:
        return (
          <div className="device-info-content">
            <p><span className="label">关联公司主体:</span>{content.companyName}</p>
            <p><span className="label">申请部门:</span>{content.departmentName}</p>
            <p><span className="label">申请部门负责人:</span>{content.departmentLeaderName}</p>
            <p><span className="label">申请人:</span>{content.applicantName}</p>
            <p><span className="label">设备品牌:</span> {content.deviceBrand}</p>
            <p><span className="label">设备类型:</span> {content.deviceType}</p>
            <p><span className="label">设备名称:</span> {content.deviceName}</p>
            <p><span className="label">SN编码:</span> {content.snCode}</p>
            <p><span className="label">资产编码:</span> {content.assetCode}</p>
            <p><span className="label">设备供应商:</span> {content.deviceSupplier}</p>
            <p><span className="label">采购员:</span>{content.purchasePersonName}</p>
            <p><span className="label">登记日期:</span> {content.registrationDate}</p>
            <p><span className="label">购入单价(元):</span> {content.purchasePrice}</p>
            <p><span className="label">其它购置费用(元):</span> {content.otherPurchaseCost}</p>
            <p><span className="label">购入数量:</span> {content.purchaseQuantity}</p>
            <p><span className="label">总计费用(元):</span> {content.totalCost}</p>
            <p><span className="label">保修年限:</span> {content.warrantyPeriod}</p>
            <p><span className="label">预计使用年限:</span> {content.expectedUsefulLife}</p>
            <p><span className="label">入库日期:</span> {content.storageDate}</p>
            <p><span className="label">设备说明:</span> {content.deviceDescription}</p>
          </div>
        )
      case 2:
        return (
          <div className="device-info-content">
            <p><span className="label">借出设备数量:</span> {content.count}</p>
            <p><span className="label">使用部门:</span> {content.departmentName}</p>
            <p><span className="label">使用人:</span> {content.userName}</p>
            <p><span className="label">借出日期:</span> {content.borrowDate}</p>
            <p><span className="label">关联项目:</span> {content.associatedProject}</p>
            <p><span className="label">运营方式:</span> {content.operationMode}</p>
            <p><span className="label">预计归还日期:</span> {content.expectedReturnDate}</p>
            <p><span className="label">合同编号:</span> {content.contractNumber || '-'}</p>
            <p><span className="label">借出说明:</span> {content.borrowDescription || '-'}</p>
            <p>
              <span className="label">设备SN编码:</span>
              <span>
                {content.snCodes && content.snCodes.split(',').map((snCode, index) => (
                  <div key={index}>{snCode}；</div>
                ))}
              </span>
            </p>
            {Array.isArray(content.returnMsg) && content.returnMsg.map((res, index) => (
              <p key={index}><span className="label">驳回说明:</span> {res}</p>
            ))}
          </div>
        )
      case 3:
        return (
          <div className="device-info-content">
            <p><span className="label">归还设备数量:</span> {content.count}</p>
            <p><span className="label">归还人部门:</span> {content.departmentName}</p>
            <p><span className="label">归还人:</span> {content.userName}</p>
            <p><span className="label">归还日期:</span> {content.returnDate || '-'}</p>
            <p><span className="label">归还说明:</span> {content.returnDescription || '-'}</p>
            <p>
              <span className="label">设备SN编码:</span>
              <span>
                {content.snCodes && content.snCodes.split(',').map((snCode, index) => (
                  <div key={index}>{snCode}；</div>
                ))}
              </span>
            </p>
            {Array.isArray(content.returnMsg) && content.returnMsg.map((res, index) => (
              <p key={index}><span className="label">驳回说明:</span> {res}</p>
            ))}
          </div>
        )
      case 4:
        return (
          <div className="device-info-content">
            <p><span className="label">维修单号:</span> {content.repairOrderNumber}</p>
            <p><span className="label">设备名称:</span> {content.deviceName}</p>
            <p><span className="label">SN编码:</span> {content.snCode}</p>
            <p><span className="label">资产编码:</span> {content.assetCode}</p>
            <p><span className="label">申请日期:</span> {content.applicationDate}</p>
            <p><span className="label">维修公司:</span> {content.maintenanceCompany || '-'}</p>
            <p><span className="label">维修申请人:</span> {content.applicantName}</p>
            <p><span className="label">维修经办人:</span> {content.maintenanceHandler}</p>
            <p><span className="label">故障说明:</span> {content.faultDescription || '-'}</p>
            <p><span className="label">配件费用(元):</span> {content.sparePartsCost}</p>
            <p><span className="label">技术费用(元):</span> {content.technicalCost}</p>
            <p><span className="label">维修总费用(元):</span> {content.totalMaintenanceCost}</p>
            <p><span className="label">维修说明:</span> {content.maintenanceNotes || '-'}</p>
            {Array.isArray(content.returnMsg) && content.returnMsg.map((res, index) => (
              <p key={index}><span className="label">驳回说明:</span> {res}</p>
            ))}
          </div>
        )
      case 5:
        return (
          <div className="device-info-content">
            <p><span className="label">报废单号:</span> {content.disposalOrderNumber}</p>
            <p><span className="label">设备名称:</span> {content.deviceName}</p>
            <p><span className="label">SN编码:</span> {content.snCode}</p>
            <p><span className="label">资产编码:</span> {content.assetCode}</p>
            <p><span className="label">报废日期:</span> {content.disposalDate}</p>
            <p><span className="label">报废处理人:</span> {content.disposalHandlerName}</p>
            <p><span className="label">报废说明:</span> {content.disposalDescription || '-'}</p>
            <p>
              <span className="label">附件:</span>
              <span>
                {
                  Array.isArray(content.attachmentFileUrl) ? content.attachmentFileUrl.map((item, i) => {
                    return <div key={i}><a href={item.url} target="_blank"
                      rel="noopener noreferrer">{item.name}</a></div>
                  }) : <div>{'-'}</div>
                }
              </span>
            </p>
          </div>
        )
      default:
        return null
    }
  }

  const showRejectModal = () => {
    setIsRejectModalVisible(true)
  }

  const handleRejectCancel = () => {
    setIsRejectModalVisible(false)
    setRejectReason('')
  }

  const handleRejectConfirm = async () => {
    if (rejectReason.trim() === '') {
      message.error('驳回原因不能为空')
      return
    }
    const { id } = auditData
    api.postVrDeviceProcedureAudit({ id, status: 2, message: rejectReason }).then(() => {
      message.success(`已驳回`)
      setIsRejectModalVisible(false)
      setRejectReason('')
      getList()
      onClose()
    }).catch()
  }

  const onApproveRepair = () => {
    const { id } = auditData
    api.postVrDeviceProcedureAudit({ id, status: 3 }).then(() => {
      message.success(`审批通过`)
      getList()
      onClose()
    }).catch()
  }

  return (
    <>
      <Modal
        title={typeName}
        visible={!!type}
        onCancel={onClose}
        footer={null}
      >
        <div className='modulePublic-Apply-container'>
          {loading ? (
            <div className="loading-content"><Spin tip="加载中..." /></div>
          ) : (
            <>
              {renderContent()}
              {auditData && (
                <div style={{ display: 'flex', justifyContent: 'center', gap: '8px', marginTop: '40px' }}>
                  {(type === 2 || type === 3) && <Button onClick={onClose}>取消</Button>}
                  {(type === 2 || type === 3 || type === 4) && (
                    <Button onClick={showRejectModal} type="primary" style={{ backgroundColor: '#f57c00', borderColor: '#f57c00' }}>
                      驳回
                    </Button>
                  )}
                  {type === 4 && <Button type='primary' onClick={onApproveRepair}>确认</Button>}
                </div>
              )}
            </>
          )}
        </div>
      </Modal>
      {/* 驳回弹窗 */}
      <Modal
        title="设备维修完成审批驳回"
        visible={isRejectModalVisible}
        onCancel={handleRejectCancel}
        onOk={handleRejectConfirm}
      >
        <p><span style={{ color: 'red' }}>*</span>驳回原因说明：</p>
        <Input.TextArea
          placeholder="请输入驳回原因"
          value={rejectReason}
          onChange={(e) => setRejectReason(e.target.value)}
          autosize={{ minRows: 4, maxRows: 5 }}
        />
      </Modal>
    </>
  )
}

export default DynamicModal