
/**
 * 模块名称: vr设备管理模块
 * @author zhuyan@372163.com
 */
import React, { useState, useEffect, useMemo, useRef } from 'react'
import api from '@/api'
import HeaderSearch from '@/components/HeaderSearch'
import { Link } from 'react-router-dom'
import {
  Table,
  Form,
  Button,
  Input,
  Select,
  message,
  Spin,
  DatePicker,
  Modal,
  Alert
} from 'antd'
import Auth from '@/components/AuthMiddleware'
import Import from '@/components/Import'
import Export from '@/components/Export'
import AddDevice from '../module/AddDevice'
import BorrowApply from '../module/BorrowApply'
import ReturnApply from '../module/ReturnApply'
import RepairApply from '../module/RepairApply'
import RepairFinish from '../module/RepairFinish'

const pageIndex = 1
const perPage = 10
let searchParam = {
  page: pageIndex,
  limit: perPage,
}
const { Option } = Select
const { RangePicker } = DatePicker

const Stock = (props) => {
  const { match, location, history } = props
  const { currentHeader, projectName, currentDeviceStatus } = location.state || {}
  const ImportRef = useRef()
  const productsRef = useRef()
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(pageIndex)
  const [limit, setPageSize] = useState(perPage)
  const [total, setTotal] = useState(0)
  const [dataSource, setDataSource] = useState([])
  const [authList, setAuthList] = useState([])
  const [editVisible, setEditVisible] = useState(false)
  const [addType, setAddType] = useState(0)  // 0: 新增, 1: 编辑
  const [statusType, setStatusType] = useState(null)
  const [editId, setEditId] = useState(null)
  const [queryData, setQueryData] = useState({})
  const [showImport, setShowImport] = useState(false)
  const [showExport, setShowExport] = useState(false)
  const [borrowVisible, setBorrowVisible] = useState(false)
  const [borrowType, setBorrowType] = useState(0)  // 0: 借出, 1: 编辑借出
  const [returnVisible, setReturnVisible] = useState(false)
  const [returnType, setReturnType] = useState(0)  // 0: 归还, 1: 编辑归还
  const [selectedRows, setSelectedRows] = useState([]) // 选中的项（存对象）
  const [selectedRowKeys, setSelectedRowKeys] = useState([]) // 选中的行 key（id）
  const [repairVisible, setRepairVisible] = useState(false)
  const [applyType, setApplyType] = useState(0)  // 0: 维修申请, 1: 报废申请
  const [repairData, setRepairData] = useState({})
  const [repairFinishVisible, setRepairFinishVisible] = useState(false)
  const [repairFinishType, setRepairFinishType] = useState(0)  // 0: 维修完成申请, 1: 编辑维修完成
  const [operationId, setOperationId] = useState(null) // 维修id
  const [deviceId, setDeviceId] = useState(null) // 设备id
  const [dataItem, setDataItem] = useState({
    projectName: projectName ? projectName : undefined,
    deviceName: currentHeader ? currentHeader : undefined,
    deviceStatus: currentDeviceStatus ? currentDeviceStatus : undefined
  }) // 传过来的默认筛选
  const [filteredParam, setFilteredParam] = useState({})

  useEffect(() => {
    onSelectAll()
    api.getPageAuth().then(list => setAuthList(list))

    if (currentHeader || projectName) {
      searchParam = {
        page, limit, projectName: projectName ? projectName : undefined,
        deviceName: currentHeader ? currentHeader : undefined,
        deviceStatus: currentDeviceStatus ? currentDeviceStatus : undefined
      }
      getList({
        projectName: projectName ? projectName : undefined,
        deviceName: currentHeader ? currentHeader : undefined,
        deviceStatus: currentDeviceStatus ? currentDeviceStatus : undefined
      })
      setTimeout(() => {
        handleSelectAll() // 赋值后全选
      }, 500)
    } else {
      resetParams()
      getList()
    }
  }, [])

  const resetParams = () => {
    searchParam = {
      page: pageIndex,
      limit: perPage,
    }
    setSelectedRows([])
    setSelectedRowKeys([])
    setPageSize(perPage)
  }

  const onSelectAll = () => {
    api.getDeviceQueryData().then(res => {
      setQueryData(res)
    })
  }

  const getList = values => {
    if (values) {
      const { page, limit } = searchParam
      searchParam = { page, limit, ...values }
    }
    let params = { ...searchParam }
    setLoading(true)
    api.getDdevicePageList(params).then(data => {
      setPage(searchParam.page)
      setPageSize(searchParam.limit)
      setTotal(data.count)
      setDataSource(data.list)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  const onChangeTable = (pagination, filters, sorter) => {
    searchParam.page = pagination.current
    searchParam.limit = pagination.pageSize
    getList()
  }

  // 设备添加
  const onAddDevice = () => {
    setEditVisible(true)
    setAddType(0)
    setStatusType(null)
    setEditId(null)
  }

  // 设备编辑
  const onEditDevice = (data) => {
    setEditVisible(true)
    setAddType(1)
    setStatusType(data.deviceStatus)
    setEditId(data.id)
  }

  // 设备完成
  const onRepairFinish = (data) => {
    setRepairFinishVisible(true)
    setRepairFinishType(0)
    setOperationId(data.operationId)
    setDeviceId(data.id)
  }

  // 设备维修
  const onRepairDevice = (data) => {
    setRepairVisible(true)
    setApplyType(0)
    setRepairData(data)
  }

  // 设备报废
  const onDisposalDevice = (data) => {
    setRepairVisible(true)
    setApplyType(1)
    setRepairData(data)
  }

  // **多选（单个操作）**
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys, selectedRowsData) => {
      setSelectedRowKeys(selectedKeys)
      setSelectedRows(selectedRowsData.map(row => ({ id: row.id })))
    }
  }

  // **全选**
  const handleSelectAll = async () => {
    try {
      let { page, limit, ...params } = searchParam
      const res = await api.getAllDeviceSimpleList(params) // 调用后端接口获取所有 ID 和 SN
      if (res.list) {
        const allIds = res.list.map(item => item.id)
        const allData = res.list.map(item => ({ id: item.id }))

        setSelectedRowKeys(allIds)
        setSelectedRows(allData)
      }
    } catch (error) {
      message.error('全选失败')
    }
  }

  // **反选**
  const handleInvertSelection = async () => {
    try {
      let { page, limit, ...params } = searchParam
      const res = await api.getAllDeviceSimpleList(params) // 调用后端接口获取所有 ID 和 SN
      if (res.list) {
        const allIds = res.list.map(item => item.id)
        const allData = res.list.map(item => ({ id: item.id }))

        // **新的选中状态**
        let newSelectedRows = []
        let newSelectedRowKeys = []

        allIds.forEach((id, index) => {
          if (!selectedRowKeys.includes(id)) {
            newSelectedRowKeys.push(id)
            newSelectedRows.push(allData[index])
          }
        })

        setSelectedRowKeys(newSelectedRowKeys)
        setSelectedRows(newSelectedRows)
      }
    } catch (error) {
      message.error('反选失败')
    }
  }

  const cols = [{
    title: '序号',
    dataIndex: 'id',
    fixed: 'left',
    align: 'center',
    render: (text, record, index) => total - ((searchParam.page - 1) * searchParam.limit + index)
    // render: (text, record, index) => index + 1 + ((searchParam.page - 1) * searchParam.limit)
  }, {
    title: '设备名称',
    dataIndex: 'deviceName',
    fixed: 'left',
    align: 'center',
    render: (text, record) => {
      return <div style={{ wordWrap: 'break-word', wordBreak: 'break-word', maxWidth: '200px' }}>
        {text || '-'}
      </div>
    }
  }, {
    title: 'SN 编码',
    dataIndex: 'snCode',
    align: 'center',
    render: (text, record) => {
      return <div style={{ wordWrap: 'break-word', wordBreak: 'break-word', maxWidth: '240px' }}>
        {text || '-'}
      </div>
    }
  }, {
    title: '资产编码',
    dataIndex: 'assetCode',
    align: 'center',
    render: (text, record) => {
      return <div style={{ wordWrap: 'break-word', wordBreak: 'break-word', maxWidth: '240px' }}>
        {text || '-'}
      </div>
    }
  }, {
    title: '设备说明',
    dataIndex: 'deviceDescription',
    align: 'center',
    render: (text, record) => {
      return <div style={{ wordWrap: 'break-word', wordBreak: 'break-word', maxWidth: '240px' }}>
        {text || '-'}
      </div>
    }
  }, {
    title: '设备供应商',
    dataIndex: 'deviceSupplier',
    align: 'center',
    render: (text, record) => {
      return <div style={{ wordWrap: 'break-word', wordBreak: 'break-word', maxWidth: '240px' }}>
        {text || '-'}
      </div>
    }
  }, {
    title: '设备品牌',
    dataIndex: 'deviceBrand',
    align: 'center',
  }, {
    title: '设备类型',
    dataIndex: 'deviceType',
    align: 'center',
  }, {
    title: '登记日期',
    dataIndex: 'registrationDate',
    align: 'center',
  }, {
    title: '公司主体',
    dataIndex: 'companyName',
    align: 'center',
  }, {
    title: '申请部门',
    dataIndex: 'departmentName',
    align: 'center',
  }, {
    title: '申请人',
    dataIndex: 'applicantName',
    align: 'center',
  }, {
    title: '设备状态',
    dataIndex: 'deviceStatus',
    align: 'center',
  }, {
    title: '使用部门',
    dataIndex: 'usageDepartmentName',
    align: 'center',
    render: (text) => text || '-'
  }, {
    title: '使用人',
    dataIndex: 'usagePersonName',
    align: 'center',
    render: (text) => text || '-'
  }, {
    title: '借出日期',
    dataIndex: 'loanDate',
    align: 'center',
    render: (text) => text || '-'
  }, {
    title: '关联项目',
    dataIndex: 'projectName',
    align: 'center',
    render: (text) => text || '-'
  }, {
    title: '运营方式',
    dataIndex: 'operationMode',
    align: 'center',
    render: (text) => text || '-'
  }, {
    title: '预计归还日期',
    dataIndex: 'expectedReturnDate',
    align: 'center',
    render: (text) => text || '-'
  }, {
    title: '实际归还日期',
    dataIndex: 'actualReturnDate',
    align: 'center',
    render: (text) => text || '-'
  }, {
    title: '操作',
    dataIndex: 'actions',
    align: 'center',
    fixed: 'right',
    render: (text, record) => (
      <div style={{ display: 'flex', minWidth: '80px', gap: '8px', justifyContent: 'center' }}>
        {
          <Auth auths={authList} code="device-repair-complete">
            {
              record.isCanRepairComplete &&
              <a onClick={() => onRepairFinish(record)}>维修完成</a>
            }
          </Auth>
        }
        {
          <Auth auths={authList} code="device-repair">
            {
              record.isCanRepair &&
              <a onClick={() => onRepairDevice(record)}>维修</a>
            }
          </Auth>
        }
        {
          <Auth auths={authList} code="device-scrap">
            {
              record.isCanScrap &&
              <a onClick={() => onDisposalDevice(record)}>报废</a>
            }
          </Auth>
        }
        {
          <Auth auths={authList} code="edit">
            <a onClick={() => onEditDevice(record)}>编辑</a>
          </Auth>
        }
        {
          <Link to={{ pathname: `${match.path}/detail`, state: { rowInfo: record } }}>历程</Link>
        }
      </div>
    )
  }
  ]

  const searchItems = useMemo(() => {
    let searchTempArr = [
      {
        label: '设备名称',
        name: 'deviceName',
        component: <Input placeholder="请输入设备名称" />,
      },
      {
        label: 'SN 编码',
        name: 'snCode',
        component: <Input placeholder="请输入 SN 编码" />,
      },
      {
        label: '资产编码',
        name: 'assetCode',
        component: <Input placeholder="请输入资产编码" />,
      },
      {
        label: '关联项目',
        name: 'projectName',
        component: (
          <Select placeholder="全部"
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
          >
            <Option value="select-all">全部</Option>
            {
              queryData.projectNameList?.map((v, i) => {
                return <Option value={v.id} key={i}>{v.name}</Option>
              })
            }
          </Select>
        ),
      },
      {
        label: '申请部门',
        name: 'departmentId',
        component: <Select
          placeholder="全部"
          mode="multiple"
          allowClear
          showSearch
          optionFilterProp="children"
        >
          <Option value="select-all">全部</Option>
          {queryData.departmentIdList?.map((v, i) =>
            <Option value={v.id} key={i}>{v.name}</Option>
          )}
        </Select>

      },
      {
        label: '申请人',
        name: 'applicantId',
        component: (
          <Select placeholder="全部"
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
          >
            <Option value="select-all">全部</Option>
            {
              queryData.applicantIdList?.map((v, i) => {
                return <Option value={v.id} key={i}>{v.name}</Option>
              })
            }
          </Select>
        ),
      },
      {
        label: '使用部门',
        name: 'usageDepartmentName',
        component: (
          <Select placeholder="全部"
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
          >
            <Option value="select-all">全部</Option>
            {
              queryData.usageDepartmentNameList?.map((v, i) => {
                return <Option value={v.id} key={i}>{v.name}</Option>
              })
            }
          </Select>
        ),
      },
      {
        label: '使用人',
        name: 'usagePersonName',
        component: (
          <Select placeholder="全部"
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children">
            <Option value="select-all">全部</Option>
            {
              queryData.usagePersonNameList?.map((v, i) => {
                return <Option value={v.id} key={i}>{v.name}</Option>
              })
            }
          </Select>
        ),
      },
      {
        label: '设备供应商',
        name: 'deviceSupplier',
        component: (
          <Select placeholder="全部"
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
          >
            <Option value="select-all">全部</Option>
            {
              queryData.deviceSupplierList?.map((v, i) => {
                return <Option value={v.id} key={i}>{v.name}</Option>
              })
            }
          </Select>
        ),
      },
      {
        label: '设备状态',
        name: 'deviceStatus',
        component: (
          <Select placeholder="全部"
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
          >
            <Option value="select-all">全部</Option>
            {
              queryData.deviceStatusList?.map((v, i) => {
                return <Option value={v.id} key={i}>{v.name}</Option>
              })
            }
          </Select>
        ),
      },
      {
        label: '设备类型',
        name: 'deviceType',
        component: (
          <Select placeholder="全部"
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
          >
            <Option value="select-all">全部</Option>
            {
              queryData.deviceTypeList?.map((v, i) => {
                return <Option value={v.id} key={i}>{v.name}</Option>
              })
            }
          </Select>
        ),
      },
      {
        label: '运营方式',
        name: 'operationMode',
        component: (
          <Select placeholder="全部"
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
          >
            <Option value="select-all">全部</Option>
            {
              queryData.operationModeList?.map((v, i) => {
                return <Option value={v.id} key={i}>{v.name}</Option>
              })
            }
          </Select>
        ),
      },
      {
        label: '登记日期',
        name: 'range:registrationDate',
        component: <RangePicker style={{ width: '100%' }} />,
      },
      {
        label: '借出日期',
        name: 'range:loanDate',
        component: <RangePicker style={{ width: '100%' }} />,
      },
      {
        label: '设备品牌',
        name: 'deviceBrand',
        component: (
          <Select placeholder="全部"
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
          >
            <Option value="select-all">全部</Option>
            {
              queryData.deviceBrandList?.map((v, i) => {
                return <Option value={v.id} key={i}>{v.name}</Option>
              })
            }
          </Select>
        ),
      },
      {
        label: '预计归还日期',
        name: 'range:expectedReturnDate',
        component: <RangePicker style={{ width: '100%' }} />,
      },
      {
        label: '归还日期',
        name: 'range:actualReturnDate',
        component: <RangePicker style={{ width: '100%' }} />,
      },
      {
        label: '公司主体',
        name: 'companyId',
        component: (
          <Select placeholder="全部"
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
          >
            <Option value="select-all">全部</Option>
            {
              queryData.companyIdList?.map((v, i) => {
                return <Option value={v.id} key={i}>{v.name}</Option>
              })
            }
          </Select>
        ),
      },
    ]

    return { search: searchTempArr }
  }, [queryData])

  // 导入确定
  const onConfirmImport = (fileList) => {
    if (fileList[0].status !== 'removed') {
      api.getImportDeviceImportExcel({
        path: fileList[0].url
      }).then(res => {
        getList()
        message.success('导入成功')
        ImportRef.current.onLoading()
        setShowImport(false)
      }).catch(res => {
        // console.log('导入', res)
        ImportRef.current.onLoading()
      })
    } else {
      message.error('请导入模板')
    }
  }

  const onShowImportDialog = () => {
    ImportRef.current.onRemoved()
    setShowImport(true)
  }

  const onShowExportDialog = () => {
    setShowExport(true)
    const { page, limit, ...filteredSearchParam } = searchParam
    setFilteredParam(filteredSearchParam)
  }

  // 设备借出
  const onBorrowApply = () => {
    setBorrowType(0)
    setBorrowVisible(true)
  }

  // 设备归还
  const onReturnApply = () => {
    setReturnType(0)
    setReturnVisible(true)
  }

  return (
    <div className="vrdevice-stock-container">
      <Spin spinning={loading} tip="Loading...">
        <HeaderSearch
          {...searchItems}
          getList={getList}
          resetParams={resetParams}
          hasShowAll={false}
          datas={dataItem}
        ></HeaderSearch>

        <div className='line'></div>
        <div className="vrdevice-stock-btn">
          <Auth auths={authList} code="add">
            <Button type="primary" onClick={onAddDevice}>设备添加</Button>
          </Auth>
          <Auth auths={authList} code="import">
            <Button type="primary" className='stock-btn-space' onClick={onShowImportDialog}>设备导入</Button>
          </Auth>
          <Auth auths={authList} code="export">
            <Button type="primary" className='stock-btn-space' onClick={onShowExportDialog}>信息导出</Button>
          </Auth>
        </div>
        <Alert message={
          <>
            <span>已选择 <span style={{ color: '#168FFF' }}>{selectedRowKeys.length}</span> 项</span>
            <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }} onClick={handleSelectAll}>全选</span>

            <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }} onClick={handleInvertSelection}>反选</span>
          </>
        } type="info" showIcon />

        <Table
          size="small"
          rowSelection={rowSelection}
          columns={cols}
          rowKey="id"
          dataSource={dataSource}
          scroll={{ x: 'max-content' }}
          pagination={{
            defaultCurrent: pageIndex,
            pageSize: limit,
            total: total,
            showSizeChanger: true,
            showQuickJumper: true,
            current: page,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          onChange={onChangeTable}
        ></Table>
        <div className='bottom-btn'>
          <Auth auths={authList} code="device-borrow">
            <Button style={{ backgroundColor: '#52c41a', borderColor: '#52c41a', color: '#fff' }} onClick={onBorrowApply}>借出</Button>
          </Auth>
          <Auth auths={authList} code="device-return">
            <Button style={{ backgroundColor: '#faad14', borderColor: '#faad14', color: '#fff' }} onClick={onReturnApply}>归还</Button>
          </Auth>
        </div>
      </Spin>
      <Modal
        title={addType === 1 ? "设备信息编辑" : "设备添加"}
        width={addType === 1 && statusType ==='借出' ? 900 : 600}
        visible={editVisible}
        onCancel={() => setEditVisible(false)}
        destroyOnClose
        footer={false}
      >
        <AddDevice
          seteditVisible={setEditVisible}
          getList={getList}
          addType={addType}
          editId={editId}
          statusType={statusType}
        />
      </Modal>
      <Modal
        title={'设备借出申请'}
        visible={borrowVisible}
        onCancel={() => setBorrowVisible(false)}
        destroyOnClose
        footer={false}
      >
        <BorrowApply
          setBorrowVisible={setBorrowVisible}
          getList={getList}
          borrowType={borrowType}
          selectedRowId={selectedRows.map(item => item.id)}
          setSelectedRows={setSelectedRows}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      </Modal>
      <Modal
        title={'设备归还申请'}
        visible={returnVisible}
        onCancel={() => setReturnVisible(false)}
        destroyOnClose
        footer={false}
      >
        <ReturnApply
          setReturnVisible={setReturnVisible}
          getList={getList}
          returnType={returnType}
          selectedRowId={selectedRows.map(item => item.id)}
          setSelectedRows={setSelectedRows}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      </Modal>
      <Modal
        title={applyType === 1 ? '设备报废申请' : '设备维修申请'}
        visible={repairVisible}
        width={600}
        onCancel={() => setRepairVisible(false)}
        destroyOnClose
        footer={false}
      >
        <RepairApply
          setRepairVisible={setRepairVisible}
          getList={getList}
          applyType={applyType}
          repairData={repairData}
        />
      </Modal>
      <Modal
        title={'设备维修完成申请'}
        visible={repairFinishVisible}
        onCancel={() => setRepairFinishVisible(false)}
        destroyOnClose
        footer={false}
      >
        <RepairFinish
          setRepairFinishVisible={setRepairFinishVisible}
          getList={getList}
          repairFinishType={repairFinishType}
          operationId={operationId}
          deviceId={deviceId}
        />
      </Modal>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }}
        tplUrl="getVrDeviceExportTpl"
        fieldsUrl="getVrDeviceTplItems"
        saveUrl="getVrDeviceSaveExportTpls"
        exportUrl="getVrDeviceExport"
        method="export"
        parame={{ ExportType: 'vrDevice', id: selectedRowKeys, ...filteredParam }}
        cRef={productsRef}
      />
      <Import
        onSearch={onConfirmImport}
        visibleFile={showImport}
        onCloseImport={() => setShowImport(false)}
        ImportTemplate="/api/File/DownTemplate?url=/templates/vrDevice.xlsx&name=vr设备导入"
        ImportRef={ImportRef}
        title="设备信息导入"
      />
    </div>
  )
}

export default Form.create()(Stock)
