/**
 * 模块名称: 工资管理
 * @author xuzhongyuan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Alert,
  Button,
  Input,
  Select,
  message,
  Icon,
  Row,
  Col,
  DatePicker
} from 'antd'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import CorpFilter from '@/components/CorpFilter'
import Import from '@/components/Import'
import Export from '@/components/Export'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { MonthPicker } = DatePicker
let pageSize = 10
let currentPage = 1

let filter = {
  companyId: '',
  wageMonth: '',
  groupId: '',
  departmentId: '',
  trueName: '',
  cardNum: ''
}

let selectedIds = ''

const Salary = (props) => {
  const ImportRef = useRef()
  const productsRef = useRef()
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [authList, setAuthList] = useState([])
  const [selectedCount, setSelectedCount] = useState(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [expand, setExpand] = useState(false)
  const [depList, setDepList] = useState([])
  const [groupList, setGroupList] = useState([])
  const [showExport, setShowExport] = useState(false)
  const [showImport, setShowImport] = useState(false)
  const search = parseSearch(location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.pageSize ? +search.pageSize : 10
    filter = {
      companyId: '',
      wageMonth: '',
      groupId: '',
      departmentId: '',
      trueName: '',
      cardNum: ''
    }
    getList()
    getSelectGroupList()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const getList = () => {
    setLoading(true)
    api.getSalaryList({ ...filter, limit: pageSize, page: currentPage })
    .then(data => {
      setLoading(false)
      setList(data.list)
      setCount(data.count)
    })
    .catch(() => setLoading(false))
  }

  const getDepartmentList = () => {
    api.getSelectDepartmentList({ companyId: filter.companyId }).then(data => {
      setDepList(data.list)
    })
  }

  const getSelectGroupList = () => {
    api.getSelectGroupList({ companyId: filter.companyId }).then(data => {
      setGroupList(data.list)
    })
  }

  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      // console.log(vals)
      if (!err) {
        const month = vals.wageMonth
        if (month) {
          vals.wageMonth = month.format('YYYY-MM')
        }
        filter = { ...filter, ...vals }
        currentPage = 1
        history.replace(match.path)
        getList()
      }
    })
  }
  
  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}`)
    getList()
  }

  const onChangeCorp = (data) => {
    setDepList([])
    setSelectedCount(0)
    setSelectedRowKeys([])
    setFieldsValue({'departmentId': undefined})
    filter.departmentId = ''
    filter.companyId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getList()
    getSelectGroupList()
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
      setSelectedCount(selectedRowKeys.length)
      setSelectedRowKeys(selectedRowKeys)
      selectedIds = selectedRowKeys
    },
    selectedRowKeys: selectedRowKeys
  }

  const onToggle = () => {
    setExpand(!expand)
  }

  const onReset = () => {
    resetFields()
    filter = {
      companyId: filter.companyId,
      wageMonth: '',
      groupId: '',
      departmentId: '',
      trueName: '',
      cardNum: ''
    }
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  // 导入确定
  const onConfirmImport = (fileList) => {
    if(fileList.length > 0) {
      if (fileList[0].status !== 'removed') {
        api.setWageSalaryImport({
          uploadUrl: fileList[0].url
        }).then(res => {
          getList()
          message.success('导入成功')
          ImportRef.current.onLoading()
          setShowImport(false)
        }).catch(res => {
          // console.log('导入', res)
          ImportRef.current.onLoading()
        })
      } else {
        message.error('请导入模板')
      }
    } else {
      message.error('请导入模板')
    }
  }

  const onShowImportDialog = () => {
    ImportRef.current.onRemoved()
    setShowImport(true)
  }

  const onShowExportDialog = () => {
    setShowExport(true)
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  return (
    <>
      <CorpFilter onChange={onChangeCorp} />
      <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo" style={{paddingBottom: 10}}>
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={6}>
                <FormItem label="工资月份" labelCol={{span: 8}}>
                  {getFieldDecorator('wageMonth')(<MonthPicker style={{width: '100%'}} placeholder="请选择月份" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="事业群" labelCol={{span: 8}}>
                  {getFieldDecorator('groupId')(
                    <Select 
                      placeholder="请选择"
                      showSearch
                      optionFilterProp="children"
                      dropdownMatchSelectWidth={false}
                    >
                      {groupList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="所属部门" labelCol={{span: 8}}>
                  {getFieldDecorator('departmentId')(
                    <Select 
                      placeholder="请选择"
                      showSearch
                      optionFilterProp="children"
                      dropdownMatchSelectWidth={false}
                      onDropdownVisibleChange={bool => bool && getDepartmentList()}
                    >
                      {depList.map(v => {
                        return !filter.companyId.length ? <Option title={v.name + '（' + v.companyName + '）'} key={v.id}>{v.name}（{v.companyName}）</Option> : <Option title={v.name} key={v.id}>{v.name}</Option>
                      })}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="员工姓名" labelCol={{span: 8}}>
                  {getFieldDecorator('trueName', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand?'':'hide'}>
              <Col span={6}>
                <FormItem label="身份证号" labelCol={{span: 8}}>
                  {getFieldDecorator('cardNum', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
            </Row>
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle} style={{cursor: 'pointer', fontSize: '12px', color: '#1890ff'}}>
            <span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
          </span>
        </div>
        <div className="search-btns">
          <Button onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
        </div>
      </Form>
      <div className="line"></div>
      <div className="add-wrap">
        <Auth auths={authList} code="operate">
          {/* <Upload {...uploadProps}> */}
          <Button type="primary" icon="download" onClick={onShowImportDialog}>导入</Button>
          &nbsp;
          {/* </Upload> */}
          <Button type="primary" icon="upload" onClick={onShowExportDialog}>导出</Button>
        </Auth>
      </div>
      <Alert className="corp-count" message={
        <>
          <span>已选择 <span style={{color: '#168FFF'}}>{selectedCount}</span> 项</span>
          <span style={{ marginLeft: 20 }}>总共{count}条数据</span>
        </>
      } type="info" showIcon/>
      <Table
        size="middle"
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{x: 10000}}
        onChange={onChangeTable}
        rowSelection={rowSelection}
      >
        <Column title="员工编号" dataIndex="employeeNo" fixed="left" width={150}/>
        <Column title="真实姓名" dataIndex="employeeName" fixed="left" width={100} />
        <Column title="工资月份" dataIndex="wageMonth"/>
        <Column title="直属上级" dataIndex="leaderName" />
        <Column title="事业群" dataIndex="groupName" />
        <Column title="部门" dataIndex="departmentName"/>
        <Column title="身份证号" dataIndex="cardNum" width={200} />
        <Column title="银行卡号" dataIndex="bankNo" width={200} />
        <Column title="所属公司" dataIndex="companyName" />
        <Column title="能否报销" dataIndex="reimbursement" />
        <Column title="待遇级别" dataIndex="treatmentLevel" />
        <Column title="任务基数" dataIndex="taskBase" />
        <Column title="是否转正" dataIndex="isFormal" />
        <Column title="3.17分界" dataIndex="boundary" />
        <Column title="是否实习" dataIndex="isPracticel" />
        <Column title="转正日期" key="formalDate" render={text => text.formalDate.split(' ')[0]} />
        <Column title="是否校招" dataIndex="campusRecruitment" />
        <Column title="在职状况" dataIndex="isOnJob" />
        <Column title="是否发放" dataIndex="grant" />
        <Column title="基础薪资" dataIndex="baseSalary" />
        <Column title="应发岗位工资" dataIndex="stationWage" />
        <Column title="工作日" dataIndex="workDay" />
        <Column title="未上天数" dataIndex="dayOff" />
        <Column title="未上扣款" dataIndex="noWorkDeduction" />
        <Column title="事假天数" dataIndex="things" />
        <Column title="事假扣款" dataIndex="leaveDeduction" />
        <Column title="病假天数" dataIndex="sick" />
        <Column title="病假扣款" dataIndex="sickleaveDeduction" />
        <Column title="迟早忘次数" dataIndex="forgetNum" />
        <Column title="迟早忘扣款" dataIndex="deduction" />
        <Column title="旷工天数" dataIndex="absenteeism" />
        <Column title="旷工扣款" dataIndex="absenteeismDeduction" />
        <Column title="是否自备电脑" dataIndex="selfComputer" />
        <Column title="电脑补助" dataIndex="computerSubsidy" />
        <Column title="其它" dataIndex="other" />
        <Column title="应发工资" dataIndex="payOffWage" />
        <Column title="其它扣款" dataIndex="otherDeduction" />
        <Column title="快递扣款" dataIndex="expressDeduction" />
        <Column title="扣款合计" dataIndex="deductionCount" />
        <Column title="职级" dataIndex="jobLevel" />
        <Column title="部门业绩" dataIndex="performance" />
        <Column title="部门提比" dataIndex="proportion" />
        <Column title="部门提成" dataIndex="royalty" />
        <Column title="个人业绩" dataIndex="selfPerformance" />
        <Column title="个人提比" dataIndex="selfProportion" />
        <Column title="个人提成" dataIndex="selfroyalty" />
        <Column title="主项业绩" dataIndex="mainPerformance" />
        <Column title="主项提比" dataIndex="mainProportion" />
        <Column title="主项提成" dataIndex="mainRoyalty" />
        <Column title="副项业绩" dataIndex="subsidiaryPerformance" />
        <Column title="副项提比" dataIndex="subsidiaryProportion" />
        <Column title="副项提成" dataIndex="subsidiaryRoyalty" />
        <Column title="季度" dataIndex="quarter" />
        <Column title="应发绩效" dataIndex="achievements" />
        <Column title="绩效完成值" dataIndex="finishAchievements" />
        <Column title="实发绩效" dataIndex="realAchievements" />
        <Column title="应发项目奖金" dataIndex="projectBonus" />
        <Column title="考核结果" dataIndex="assessmentResult" />
        <Column title="实发项目奖金" dataIndex="realBonus" />
        <Column title="绩效未达扣除" dataIndex="achievementsDeduction" />
        <Column title="奖金" dataIndex="bonus" />
        <Column title="实发提成" dataIndex="realRoyalty" />
        <Column title="养老" dataIndex="stfpension" />
        <Column title="失业" dataIndex="stfunemployment" />
        <Column title="医疗" dataIndex="stfmedical" />
        <Column title="工伤" dataIndex="injury" />
        <Column title="生育" dataIndex="bear" />
        <Column title="社保公司合计" dataIndex="socialSecurityUnitTotal" />
        <Column title="个人养老" dataIndex="perpension" />
        <Column title="个人失业" dataIndex="perunemployment" />
        <Column title="个人医疗" dataIndex="permedical" />
        <Column title="社保个人合计" dataIndex="socialSecuritySelfTotal" />
        <Column title="公积金公司" dataIndex="stfPayment" />
        <Column title="公积金个人" dataIndex="personPayment" />
        <Column title="免税合计" dataIndex="noTaxTotal" />
        <Column title="薪资合计" dataIndex="salaryTotal" />
        <Column title="保险公积金后合计" dataIndex="insuranceTotal" />
        <Column title="应计税所得" dataIndex="incomeTax" />
        <Column title="应缴个税" dataIndex="payableTax" />
        <Column title="最终实发" dataIndex="finalReal" />
        <Column title="个税应计" dataIndex="selfTax" />
        <Column title="个税税率" dataIndex="selfTaxRate" />
        <Column title="速算扣除数" dataIndex="deductionNum" />
      </Table>
      <Import 
        onSearch={onConfirmImport}
        visibleFile={showImport}
        onCloseImport={() => setShowImport(false)}
        ImportTemplate="/api/File/DownTemplate?url=/templates/salary.xlsx&name=工资管理导入模板"
        ImportRef={ImportRef}
      />
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }}
        tplUrl="getWageLevelExportTpl"
        fieldsUrl="getWageLevelTplItems"
        saveUrl="saveWageLevelTpl"
        exportUrl="exportWageLevel"
        method="getwagelist"
        parame={{ ExportTyp: 'wage', id: selectedIds, ...filter }}
        cRef={productsRef}
      />
    </>
  )
}

export default Form.create()(Salary)