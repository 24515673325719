/*
 * @Description: 
 * @Author: 杨志航<yangzhihang@372163.com>
 * @Date: 2022-01-08 16:29:36
 */

export const regExt = {
  phone: /^1[3456789]\d{9}$/,
  mail: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{1,30})$/,
  identity: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
  bankNo: /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/,
  password: /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])^[0-9A-Za-z\S]{7,30}$/,
  passwordModify: /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])^[0-9A-Za-z\S]{7,20}$/,
}

export const politicOptions = [{
  name: '群众',
  id: 1,
}, {
  name: '团员',
  id: 2,
}, {
  name: '预备党员',
  id: 3,
}, {
  name: '党员',
  id: 4,
}, {
  name: '其它',
  id: 5,
}]

export const recruitmentSourceOptions = [{
  name: '拉钩',
  id: 1,
}, {
  name: '赶集',
  id: 2,
}, {
  name: '前程无忧',
  id: 3,
}, {
  name: '58同城',
  id: 4,
}, {
  name: '智联',
  id: 5,
}, {
  name: '中华英才',
  id: 6,
}, {
  name: 'BOSS直聘',
  id: 7,
}, {
  name: '猎头',
  id: 8,
}, {
  name: '其它',
  id: 9,
}, {
  name: '内部推荐',
  id: 0,
}]

export const marriageOptions = [{
  name: '未婚',
  id: 0,
}, {
  name: '已婚',
  id: 1,
}, {
  name: '离异',
  id: 2,
}]

export const educationOptions = [{
  name: '初中',
  id: 1,
}, {
  name: '中专',
  id: 2,
}, {
  name: '高中',
  id: 3,
}, {
  name: '大专',
  id: 4,
}, {
  name: '本科',
  id: 5,
}, {
  name: '硕士研究生',
  id: 6,
}, {
  name: '博士研究生',
  id: 7,
}]

export const majorOptions = [{
  name: '文史类',
  id: 1,
}, {
  name: '理工类',
  id: 2,
}]

export const residenceOptions = [{
  name: '本市农户',
  id: 0,
}, {
  name: '本市城镇',
  id: 1,
}, {
  name: '外地农户',
  id: 2,
}, {
  name: '外地城镇',
  id: 3,
}]

export const staffTypeOptions = [{
  name: '销售',
  id: 1,
}, {
  name: '客服',
  id: 2,
}, {
  name: '职能',
  id: 3,
}, {
  name: '直播',
  id: 4,
}]

export const leaveJobTypeOptions = [{
  name: '主动离职',
  id: 0,
}, {
  name: '被动离职',
  id: 1,
}]

export const wagesTimeOptions = [{
  name: '上午',
  id: 1,
}, {
  name: '下午',
  id: 2,
}]

export const workColumns = [
  {
    title: '开始时间',
    dataIndex: 'startTime',
    inputType: 'date',
  },
  {
    title: '结束时间',
    dataIndex: 'endTime',
    inputType: 'date',
  },
  {
    title: '单位名称',
    dataIndex: 'company',
  },
  {
    title: '职务',
    dataIndex: 'position',
    required: false
  },
  {
    title: '证明人',
    dataIndex: 'witness',
  },
  {
    title: '联系方式',
    dataIndex: 'mobile',
    inputType: 'number',
    regExtType: 'phone'
  },
  {
    title: '离职原因',
    dataIndex: 'leavReason',
    required: false
  },
]

export const familyColumns = [
  {
    title: '姓名',
    dataIndex: 'name',
  },
  {
    title: '与本人关系',
    dataIndex: 'relationship',
  },
  {
    title: '年龄',
    dataIndex: 'age',
    inputType: 'number',
  },
  {
    title: '工作单位',
    dataIndex: 'company',
    required: false
  },
  {
    title: '职位',
    dataIndex: 'position',
    required: false

  },
  {
    title: '联系方式',
    dataIndex: 'mobile',
    inputType: 'number',
    regExtType: 'phone'
  },
]
