
/**
 * 模块名称: vr设备审核
 * @author zhuyan@372163.com
 */
import React, { useState, useEffect, useMemo, useRef } from 'react'
import api from '@/api'
import HeaderSearch from '@/components/HeaderSearch'
import {
  Table,
  Form,
  Select,
  Spin,
  DatePicker,
  Modal,
} from 'antd'
import { useSelector } from "react-redux"
import Auth from '@/components/AuthMiddleware'
import BorrowApply from './module/BorrowApply'
import ReturnApply from './module/ReturnApply'
import RepairFinish from './module/RepairFinish'
import TypeDetail from './module/TypeDetail'

const pageIndex = 1
const perPage = 10
let searchParam = {
  page: pageIndex,
  limit: perPage,
}
const { Option } = Select
const { RangePicker } = DatePicker
const statusArray = [
  { value: 1, label: '申请' },
  { value: 2, label: '驳回' },
  { value: 3, label: '通过' }
]
const operationArray = [
  { value: 1, label: '借出' },
  { value: 2, label: '归还' },
  { value: 3, label: '保修' }
]
const DeviceAudit = (props) => {
  const { match, location, history } = props
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(pageIndex)
  const [limit, setPageSize] = useState(perPage)
  const [total, setTotal] = useState(0)
  const [dataSource, setDataSource] = useState([])
  const [authList, setAuthList] = useState([])
  const [editId, setEditId] = useState(null)
  const [queryData, setQueryData] = useState({})
  const [borrowVisible, setBorrowVisible] = useState(false)
  const [borrowType, setBorrowType] = useState(0)  // 0: 借出, 1: 编辑借出
  const [returnVisible, setReturnVisible] = useState(false)
  const [returnType, setReturnType] = useState(0)  // 0: 归还, 1: 编辑归还
  const [repairFinishVisible, setRepairFinishVisible] = useState(false)
  const [repairFinishType, setRepairFinishType] = useState(0)  // 0: 维修完成申请, 1: 编辑维修完成
  const [operationId, setOperationId] = useState(null) // 维修id
  const [modalType, setModalType] = useState(null)
  const [modalDataId, setModalDataId] = useState(null)
  const [modaName, setModalName] = useState(null)
  const [auditData, setAuditData] = useState(null)
  const userInfo = useSelector(state => state.userInfo)

  useEffect(() => {
    resetParams()
    onSelectAll()
    getList()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const resetParams = () => {
    searchParam = {
      page: pageIndex,
      limit: perPage,
    }
    setPageSize(perPage)
  }

  const onSelectAll = () => {
    api.getVrDeviceProcedureQueryData().then(res => {
      setQueryData(res)
    })
  }

  const getList = values => {
    if (values) {
      const { page, limit } = searchParam
      searchParam = { page, limit, ...values }
    }
    let params = { ...searchParam }
    setLoading(true)
    api.getVrDeviceProcedureList(params).then(data => {
      setPage(searchParam.page)
      setPageSize(searchParam.limit)
      setTotal(data.count)
      setDataSource(data.list)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  const onChangeTable = (pagination, filters, sorter) => {
    searchParam.page = pagination.current
    searchParam.limit = pagination.pageSize
    getList()
  }

  // 点击详情
  const handleOpenModal = (data, mode) => { // mode为2是审核
    if (data.type === 1) { // 借出
      setModalType(2)
      setModalName(mode === 2 ? '审批' : '详情')
    } else if (data.type === 2) { // 归还
      setModalType(3)
      setModalName(mode === 2 ? '审批' : '详情')
    } else if (data.type === 3) { // 维修
      setModalType(4)
    }
    setModalDataId(data.sourceId)
    if (mode === 2) { // 审核
      setAuditData(data)
    } else {
      setAuditData(null)
    }
  }

  const handleCloseModal = () => {
    setModalType(null)
    setModalDataId(null)
    setModalName(null)
    setAuditData(null)
  }

  // 点击编辑
  const onEditModle = (data) => {
    if (data.type === 1) { // 借出
      setBorrowType(1)
      setEditId(data.sourceId)
      setBorrowVisible(true)
    } else if (data.type === 2) { // 归还
      setReturnType(1)
      setEditId(data.sourceId)
      setReturnVisible(true)
    } else if (data.type === 3) { // 维修
      setRepairFinishVisible(true)
      setRepairFinishType(1)
      setOperationId(data.sourceId)
    }
  }

  const cols = [
    {
      title: '序号',
      dataIndex: 'id',
      fixed: 'left',
      align: 'center',
      render: (text, record, index) => total - ((searchParam.page - 1) * searchParam.limit + index)
    },
    {
      title: '设备操作',
      dataIndex: 'type',
      fixed: 'left',
      align: 'center',
      render: (text) => {
        switch (text) {
          case 1:
            return '借出'
          case 2:
            return '归还'
          case 3:
            return '维修'
          default:
            return '-'
        }
      }
    },
    {
      title: '设备数量',
      dataIndex: 'info.count',
      align: 'center',
      render: (text) => text || '-'
    },
    {
      title: '使用部门',
      dataIndex: 'info.departmentName1',
      align: 'center',
      render: (type, record) => {
        if (record.type === 1) {
          return record.info.departmentName || '-'
        }
        return '-'
      }
    },
    {
      title: '使用人',
      dataIndex: 'info.userName1',
      align: 'center',
      render: (type, record) => {
        if (record.type === 1) {
          return record.info.userName || '-'
        }
        return '-'
      }
    },
    {
      title: '借出日期',
      dataIndex: 'info.borrowDate',
      align: 'center',
      render: (text) => text || '-'
    },
    {
      title: '关联项目',
      dataIndex: 'info.associatedProject',
      align: 'center',
      render: (text) => text || '-'
    },
    {
      title: '运营方式',
      dataIndex: 'info.operationMode',
      align: 'center',
      render: (text) => text || '-'
    },
    {
      title: '预计归还日期',
      dataIndex: 'info.expectedReturnDate',
      align: 'center',
      render: (text) => text || '-'
    },
    {
      title: '归还部门',
      dataIndex: 'info.departmentName2',
      align: 'center',
      render: (type, record) => {
        if (record.type === 2) {
          return record.info.departmentName || '-'
        }
        return '-'
      }
    },
    {
      title: '归还人',
      dataIndex: 'info.userName2',
      align: 'center',
      render: (type, record) => {
        if (record.type === 2) {
          return record.info.userName || '-'
        }
        return '-'
      }
    },
    {
      title: '归还日期',
      dataIndex: 'info.returnDate',
      align: 'center',
      render: (text) => text || '-'
    },
    {
      title: '审批状态',
      dataIndex: 'status',
      align: 'center',
      render: (text) => {
        switch (text) {
          case 1:
            return '申请'
          case 2:
            return <div style={{ color: 'red' }}>{'驳回'}</div>
          case 3:
            return <div style={{ color: 'green' }}>{'通过'}</div>
          default:
            return '-'
        }
      }
    },
    {
      title: '申请人',
      dataIndex: 'applicantName',
      align: 'center',
      render: (text) => text || '-'
    },
    {
      title: '审批人',
      dataIndex: 'verifyName',
      align: 'center',
      render: (text) => text || '-'
    },
    {
      title: '申请时间',
      dataIndex: 'createdAt',
      align: 'center',
      render: (text) => text || '-'
    },
    {
      title: '审批时间',
      dataIndex: 'verifyAt',
      align: 'center',
      render: (text) => text || '-'
    }, {
      title: '操作',
      dataIndex: 'actions',
      align: 'center',
      fixed: 'right',
      render: (text, record) => {
        return (
          <div style={{ display: 'flex', minWidth: '80px', gap: '8px', justifyContent: 'center' }}>
            {renderAuditButton(record, userInfo)}
          {
            record.applicantId === userInfo.id && record.status === 2 &&
            <a onClick={() => onEditModle(record)}>编辑</a>
          }
          {
            <a onClick={() => handleOpenModal(record)}>详情</a>
          }
        </div>
      )
    }
    }
  ]

  const renderAuditButton = (record, userInfo) => {
    if (record.type === 1 || record.type === 2) {
      return (
        <Auth auths={authList} code="device-audit">
          {record.status === 1 && <a onClick={() => handleOpenModal(record, 2)}>审核</a>}
        </Auth>
      )
    }
    if (record.type === 3 && record.applicantId === userInfo.id && record.status === 1) {
      return <a onClick={() => handleOpenModal(record, 2)}>审核</a>
    }
    return null
  }

  const searchItems = useMemo(() => {
    let searchTempArr = [
      {
        label: '使用部门',
        name: 'departmentName1',
        component: (
          <Select placeholder="全部"
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
          >
            <Option value="select-all">全部</Option>
            {
              queryData.usageDepartmentNameList?.map((v, i) => {
                return <Option value={v} key={i}>{v}</Option>
              })
            }
          </Select>
        ),
      },
      {
        label: '使用人',
        name: 'userName1',
        component: (
          <Select placeholder="全部"
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children">
            <Option value="select-all">全部</Option>
            {
              queryData.usagePersonNameList?.map((v, i) => {
                return <Option value={v} key={i}>{v}</Option>
              })
            }
          </Select>
        ),
      },
      {
        label: '关联项目',
        name: 'associatedProject',
        component: (
          <Select placeholder="全部"
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
          >
            <Option value="select-all">全部</Option>
            {
              queryData.projectNameList?.map((v, i) => {
                return <Option value={v} key={i}>{v}</Option>
              })
            }
          </Select>
        ),
      },
      {
        label: '运营方式',
        name: 'operationMode',
        component: (
          <Select placeholder="全部"
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
          >
            <Option value="select-all">全部</Option>
            {
              queryData.operationModeList?.map((v, i) => {
                return <Option value={v} key={i}>{v}</Option>
              })
            }
          </Select>
        ),
      },
      {
        label: '设备操作',
        name: 'type',
        component: (
          <Select placeholder="全部"
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
          >
            <Option value="select-all">全部</Option>
            {
              operationArray?.map((v, i) => {
                return <Option value={v.value} key={i}>{v.label}</Option>
              })
            }
          </Select>
        ),
      },
      {
        label: '审批状态',
        name: 'status',
        component: (
          <Select placeholder="全部"
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
          >
            <Option value="select-all">全部</Option>
            {
              statusArray?.map((v, i) => {
                return <Option value={v.value} key={i}>{v.label}</Option>
              })
            }
          </Select>
        ),
      },
      {
        label: '归还部门',
        name: 'departmentName2',
        component: (
          <Select placeholder="全部"
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children"
          >
            <Option value="select-all">全部</Option>
            {
              queryData.returnDepartmentNameList?.map((v, i) => {
                return <Option value={v} key={i}>{v}</Option>
              })
            }
          </Select>
        ),
      },
      {
        label: '归还人',
        name: 'userName2',
        component: (
          <Select placeholder="全部"
            mode="multiple"
            allowClear
            showSearch
            optionFilterProp="children">
            <Option value="select-all">全部</Option>
            {
              queryData.returnPersonNameList?.map((v, i) => {
                return <Option value={v} key={i}>{v}</Option>
              })
            }
          </Select>
        ),
      },
      {
        label: '借出日期',
        name: 'rangeSE:borrowDate',
        component: <RangePicker style={{ width: '100%' }} />,
      },
      {
        label: '预计归还期',
        name: 'rangeSE:expectedReturnDate',
        component: <RangePicker style={{ width: '100%' }} />,
      },
      {
        label: '归还日期',
        name: 'rangeSE:actualReturnDate',
        component: <RangePicker style={{ width: '100%' }} />,
      }
    ]

    return { search: searchTempArr }
  }, [queryData])



  return (
    <div className="vrdevice-stock-container">
      <Spin spinning={loading} tip="Loading...">
        <HeaderSearch
          {...searchItems}
          getList={getList}
          resetParams={resetParams}
          hasShowAll={false}
        // datas={{ 'username': 11111 }}
        ></HeaderSearch>

        <div className='line'></div>
        <div className="vrdevice-stock-btn">
        </div>
        <Table
          size="small"
          columns={cols}
          rowKey="id"
          dataSource={dataSource}
          scroll={{ x: 'max-content' }}
          // bordered
          pagination={{
            defaultCurrent: pageIndex,
            pageSize: limit,
            total: total,
            showSizeChanger: true,
            showQuickJumper: true,
            current: page,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          onChange={onChangeTable}
        ></Table>
      </Spin>
      <Modal
        title={'设备借出编辑'}
        visible={borrowVisible}
        onCancel={() => setBorrowVisible(false)}
        destroyOnClose
        footer={false}
      >
        <BorrowApply
          setBorrowVisible={setBorrowVisible}
          getList={getList}
          borrowType={borrowType}
          editId={editId}
        />
      </Modal>
      <Modal
        title={'设备归还编辑'}
        visible={returnVisible}
        onCancel={() => setReturnVisible(false)}
        destroyOnClose
        footer={false}
      >
        <ReturnApply
          setReturnVisible={setReturnVisible}
          getList={getList}
          returnType={returnType}
          editId={editId}
        />
      </Modal>
      <Modal
        title={'设备维修完成编辑'}
        visible={repairFinishVisible}
        onCancel={() => setRepairFinishVisible(false)}
        destroyOnClose
        footer={false}
      >
        <RepairFinish
          setRepairFinishVisible={setRepairFinishVisible}
          getList={getList}
          repairFinishType={repairFinishType}
          operationId={operationId}
        />
      </Modal>
      <TypeDetail
        type={modalType}
        onClose={handleCloseModal}
        dataId={modalDataId}
        modaName={modaName}
        auditData={auditData}
        getList={getList}
      />
    </div>
  )
}

export default Form.create()(DeviceAudit)
