import api from 'api'
import { message } from 'antd'

export const onUrge = (url, taskId) => {
  api[url]({taskId}).then(res => {
    message.success('已成功发起催一催，下次催需要等2小时')
  })
}

// 解析search查询字符串
export const parseSearch = (search) => {
  let qs = (search.length > 0 ? search.substring(1) : ""),
    items = qs.length ? qs.split("&") : [],
    args = {},
    item = null,
    name = null,
    value = null;

  for (let i = 0, len = items.length; i < len; i++) {
    item = items[i].split("=")
    name = decodeURIComponent(item[0])
    value = decodeURIComponent(item[1])
    if (name.length) {
      args[name] = value
    }
  }
  return args
}

export const bubbleSort = list => {
  for (let i = 0; i < list.length; i++) {
    for (let j = 0; j < list.length - 1 - i; j++) {
      if (list[j].sort > list[j + 1].sort) {
        const item = list[j]
        list[j] = list[j + 1]
        list[j + 1] = item
      }
    }
  }
  return list
}

/**
 * 获取近n天的时期，比如说近1天，就获取昨天的日期
 * @param n
 */
export const getLastNDate = (n) => {
  const date = new Date();
  date.setTime(date.getTime() - n * 24 * 60 * 60 * 1000);
  return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
}
/**
 * debounce
 */
//这个是后调用防抖
export const debounceRight = (fn, delay) => {
  let timer = null
  return function(...args){
    if(timer){
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      timer = null
      clearTimeout(timer)
      fn.apply(this, args)
    }, delay)
  }
}
//先调用防抖，也就是先调用函数，在等待一段时间之后再进行下一次调用，这样做可以保证第一次点击的时候不延迟
export const debounce = (fn, delay) => {
  delay = delay || 1000
  let timer = null
  return function (...args) {
    if (!timer) fn.apply(this, args)
    clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
    }, delay)
  }
}

// 搜索框输入防抖
export const debounceSeek = (fn, delay) => {
  let timer
  return function(...args){
    if(timer){
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      timer = null
      fn.apply(this, args)
    }, delay)
  }
}

/**
 * 对象及数组深拷贝
 */
export const deepCopyObj = (obj) => {
  if (Object.prototype.toString.call(obj) == '[object Object]') {
    let newObj = {}
    for (let key in obj) {
      if (Object.prototype.toString.call(obj[key]) == '[object Object]') {
        let newChildObj = deepCopyObj(obj[key])
        newObj[key] = newChildObj
      } else {
        newObj[key] = obj[key]
      }
    }
    return newObj
  } else if (Object.prototype.toString.call(obj) == '[object Array]') {
    let temp = []
    obj.forEach((item) => {
      let map
      map = deepCopyObj(item)
      temp.push(map)
    })
    return temp
  } else {
    return obj
  }
}
//在第2个数组中删除和第1个数组重复的元素，返回删除后的新数组，不改变原数组
export const delArrInArr = (arr1, arr2) => {
  let temp = [], newArr = []
  for (let i = 0; i < arr1.length; i++) {
    temp[arr1[i]] = true
  }
  for (let i = 0; i < arr2.length; i++) {
    if (!temp[arr2[i]]) {
      newArr.push(arr2[i])
    }
  }
  return newArr
}
//限制只能输入n位小数点的方法，不能输入"数字"、负号"-"(只能第一位输入负号)，中文，字符，只保留一个小数"."，首位不能是".",负号"-"后面不能有"."
export const inputInputNumFn = (value) => {
  let val = value
  //如果首位是"." 去掉"."
  if (val != '' && val.substr(0, 1) == '.') {
    val = ''
  }
  //如果非首位存在"-"，去掉"-"
  if(val != '' && val.substr(0, 1) !== '-'){
    // console.log('首位不是-')
    val = val.replace(/\-/g, '')
  }else{
    // console.log('首位是-')
    if(val.length > 1){
      //如果第二位是"."
      if(val.substr(1, 2) == '.'){
        val = '-' + val.replace(/\-./g, '-').replace(/\-/g, '')
      }else{
        val = '-' + val.replace(/\-/g, '')
      }
    }  
  }
  val = val.replace(/^0*(0\.|[1-9])/, '$1')//解决 粘贴不生效
  val = val.replace(/[^-\d.]/g, '')  //清除“数字”和“.”以外的字符
  val = val.replace(/\.{2,}/g, '.') //只保留第一个"."
  val = val.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
  val = val.replace(/^(\-)*(\d+)\.(\d{2}).*$/, '$1$2.$3')//只能输入两个小数
  if (val.indexOf('.') < 0 && val != '') {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
    if (val.substr(0, 1) == '0' && val.length == 2) {
      val = val.substr(1, val.length)
    }
  }
  return val
}

// 字符串数字转化数字类型数字
export const toNumber = (val) => {
  const n = Number(val)
  return isNaN(n) ? 0 : n
}

// 字符串数字转化数字类型小数取后四位
export const toNumberPoint = (val) => {
  const n = Number(val)
  return isNaN(n) ? (0).toFixed(4) : n.toFixed(4)
}

export const downloadFile = (val) => {
  for (let i = 0; i < val.length; i++) {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.style.height = 0;
    iframe.src = val[i];
    document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
    // 5分钟之后删除
    setTimeout(() => {
      iframe.remove();
    }, 5 * 60 * 1000);
  }
}

export const changeMoneyToChinese = money => {
    const cnNums = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"] //汉字的数字
    const cnIntRadice = ["", "拾", "佰", "仟"] //基本单位
    const cnIntUnits = ["", "万", "亿", "兆"] //对应整数部分扩展单位
    const cnDecUnits = ["角", "分", "毫", "厘"] //对应小数部分单位
    const cnInteger = "整"; //整数金额时后面跟的字符
    const cnIntLast = "元"; //整型完以后的单位
    const maxNum = 999999999999999.9999; //最大处理的数字
    let IntegerNum; //金额整数部分
    let DecimalNum; //金额小数部分
    let ChineseStr = ""; //输出的中文金额字符串
    let parts; //分离金额后用的数组，预定义
    let Symbol="";//正负值标记
    if (money == "") {
        return "";
    }

    money = parseFloat(money);
    if (money >= maxNum) {
        alert('超出最大处理数字');
        return "";
    }
    if (money == 0) {
        ChineseStr = cnNums[0] + cnIntLast + cnInteger;
        return ChineseStr;
    }
    if(money<0)
    {
        money=-money;
        Symbol="负 ";        
    }
    money = money.toString(); //转换为字符串
    if (money.indexOf(".") == -1) {
        IntegerNum = money;
        DecimalNum = '';
    } else {
        parts = money.split(".");
        IntegerNum = parts[0];
        DecimalNum = parts[1].substr(0, 4);
    }
    if (parseInt(IntegerNum, 10) > 0) { //获取整型部分转换
        let zeroCount = 0;
        let IntLen = IntegerNum.length;
        for (let i = 0; i < IntLen; i++) {
            let n = IntegerNum.substr(i, 1);
            let p = IntLen - i - 1;
            let q = p / 4;
            let m = p % 4;
            if (n == "0") {
                zeroCount++;
            }
            else {
                if (zeroCount > 0) {
                    ChineseStr += cnNums[0];
                }
                zeroCount = 0; //归零
                ChineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
            }
            if (m == 0 && zeroCount < 4) {
                ChineseStr += cnIntUnits[q];
            }
        }
        ChineseStr += cnIntLast;
        //整型部分处理完毕
    }
    if (DecimalNum != '') { //小数部分
        let decLen = DecimalNum.length;
        for (let i = 0; i < decLen; i++) {
            let n = DecimalNum.substr(i, 1);
            if (n != '0') {
                ChineseStr += cnNums[Number(n)] + cnDecUnits[i];
            }
        }
    }
    if (ChineseStr == '') {
        ChineseStr += cnNums[0] + cnIntLast + cnInteger;
    } else if (DecimalNum == '') {
        ChineseStr += cnInteger;
    }
    ChineseStr = Symbol +ChineseStr;
    
    return ChineseStr;
}

export const getDiffYmdBetweenDate = (sDate1, sDate2) => {
  const start = new Date(sDate1).getTime()
  const end = new Date(sDate2).getTime()
  if (start >= end) {
    return { year: 0, month: 0, day: 0 }
  }

  let fixDate = function(sDate){
      let aD = sDate.split('-');
      for(let i = 0; i < aD.length; i++){
          aD[i] = fixZero(parseInt(aD[i]));
      }
      return aD.join('-');
  };
  let fixZero = function(n){
      return n < 10 ? '0'+n : n;
  };
  let fixInt = function(a){
      for(let i = 0; i < a.length; i++){
          a[i] = parseInt(a[i]);
      }
      return a;
  };
  let getMonthDays = function(y, m){
      let aMonthDays = [0,31,28,31,30,31,30,31,31,30,31,30,31];
      if((y%400 == 0) || (y%4==0 && y%100!=0)){
          aMonthDays[2] = 29;
      }
      return aMonthDays[m];
  }
  let y = 0;
  let m = 0;
  let d = 0;
  let sTmp;
  let aTmp;
  sDate1 = fixDate(sDate1);
  sDate2 = fixDate(sDate2);
  if(sDate1 > sDate2){
      sTmp = sDate2;
      sDate2 = sDate1;
      sDate1 = sTmp;
  }
  let aDate1 = sDate1.split('-');
      aDate1 = fixInt(aDate1);
  let aDate2 = sDate2.split('-');
      aDate2 = fixInt(aDate2);
  //计算相差的年份
  y = aDate2[0] - aDate1[0];
  if( sDate2.replace(aDate2[0],'') < sDate1.replace(aDate1[0],'')){
      y = y - 1;
  }
  //计算月份
  aTmp = [aDate1[0]+y,aDate1[1],fixZero(aDate1[2])];
  while(true){
      if(aTmp[1] == 12){
          aTmp[0]++;
          aTmp[1] = 1;
      }else{
          aTmp[1]++;
      }
      if(([aTmp[0],fixZero(aTmp[1]),aTmp[2]]).join('-') <= sDate2){
          m++;
      } else {
          break;
      }
  }
  //计算天数
  aTmp = [aDate1[0]+y,aDate1[1]+m,aDate1[2]];
  if(aTmp[1] > 12){
      aTmp[0]++;
      aTmp[1] -= 12;
  }
  while(true){
      if(aTmp[2] == getMonthDays(aTmp[0],aTmp[1])){
          aTmp[1]++;
          aTmp[2] = 1;
      } else {
          aTmp[2]++;
      }
      sTmp = ([aTmp[0],fixZero(aTmp[1]),fixZero(aTmp[2])]).join('-');
      if(sTmp <= sDate2){
          d++;
      } else {
          break;
      }
  }
  return { year: y, month: m, day: d }
}
