/**
 * 模块名称: vr设备管理模块
 * @author zhuyan@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Stock from './Stock'
import Stats from './Stats'
import DeviceAudit from './DeviceAudit'
import './assets/style.scss'

const VrDeviceIndex = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/stock'} component={Stock} />
      <Route path={match.path + '/stats'} component={Stats} />
      <Route path={match.path + '/audit'} component={DeviceAudit} />
      <Route component={Stock} />
    </Switch>
  )
}

export default VrDeviceIndex