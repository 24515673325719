/**
 * 模块名称: 设备维修申请/设备报废申请
 * @author zhuyan@372163.com
 */
import React, { useEffect, useState, useRef } from 'react'
import { Form, Input, Button, message, Icon, Upload } from 'antd'
import moment from 'moment'
import { upLoadModule } from '@/utils/common'
import { useSelector } from "react-redux"
import api from '@/api'
import '../assets/module.scss'

const { TextArea } = Input
const FormItem = Form.Item
const { Dragger } = Upload

const ReturnDeviceForm = (props) => {
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const { setRepairVisible, getList, applyType, repairData } = props
  const [saveLoading, setSaveLoading] = useState(false)
  const userInfo = useSelector(state => state.userInfo)
  const draggerRef = useRef(null)
  const [fileList, setFileList] = useState([])

  // 填充表单数据
  useEffect(() => {
    if (repairData) {
      if (applyType === 0) {
        setFieldsValue({
          applicationDate: moment().format('YYYY-MM-DD')
        })
      } else if (applyType === 1) {
        setFieldsValue({
          disposalDate: moment().format('YYYY-MM-DD')
        })
      }
    }
  }, [repairData])

  // 提交表单
  const handleSubmit = (e) => {
    e.preventDefault()
    validateFields((err, values) => {
      if (!err) {
        const formattedValues = {
          ...values,
        }
        formattedValues.deviceId = repairData.id

        if (applyType === 1) {
          let uploadList = []
          for (let i = 0, k = fileList.length; i < k; i++) {
            if (fileList[i].status !== 'removed') {
              uploadList.push({
                url: fileList[i].url,
                name: fileList[i].name
              })
              uploadList.push(fileList[i].url)
            }
          }
          formattedValues.attachmentFileUrl = uploadList
        }
        setSaveLoading(true)
        let apiCall
        if (applyType === 0) {
          apiCall = api.deviceRepairApply(formattedValues)
        } else if (applyType === 1) {
          apiCall = api.deviceDisposalApply(formattedValues)
        }
        apiCall.then(() => {
          message.success(applyType === 0 ? '设备维修申请提交成功' : '设备报废申请提交成功')
          setSaveLoading(false)
          getList()
          setRepairVisible(false)
        }).catch(() => setSaveLoading(false))
      }
    })
  }

  // 重置表单
  const handleReset = () => {
    resetFields()
    setRepairVisible(false)
  }

  const formItemLayout = {
    labelCol: { xs: { span: 24 }, sm: { span: 6 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }
  }

  // **去掉边框的样式**
  const inputStyle = { border: 'none', boxShadow: 'none', backgroundColor: 'transparent', padding: 0 }

  // 文件上传 
  const uploadFiles = upLoadModule({
    fileList: fileList,
    setFileList: setFileList,
    draggerRef: draggerRef,
    accept: '.gif, .png, .jpg, .jpeg, .PDF, .pptx',
    allowSizeType: 2,
    limitNum: 5
  })

  return (
    <div className='modulePublic-Apply-container'>
      <Form onSubmit={handleSubmit} {...formItemLayout}>
        <FormItem style={{ marginBottom: 4 }} label="设备名称">
          <Input
            value={repairData?.deviceName || '-'}
            style={inputStyle}
            readOnly
          />
        </FormItem>
        <FormItem style={{ marginBottom: 4 }} label="SN编码">
          <Input
            value={repairData?.snCode || '-'}
            style={inputStyle}
            readOnly
          />
        </FormItem>
        <FormItem style={{ marginBottom: 4 }} label="资产编码">
          <Input
            value={repairData?.assetCode || '-'}
            style={inputStyle}
            readOnly
          />
        </FormItem>
        {
          applyType === 0 &&
          <FormItem style={{ marginBottom: 4 }} label="申请日期">
            {getFieldDecorator('applicationDate')(
              <Input style={inputStyle} />
            )}
          </FormItem>
        }
        {
          applyType === 1 &&
          <FormItem style={{ marginBottom: 4 }} label="报废日期">
            {getFieldDecorator('disposalDate')(
              <Input style={inputStyle} />
            )}
          </FormItem>

        }

        <FormItem style={{ marginBottom: 4 }} label={`${applyType === 1 ? '报废经办人' : '维修申请人'}`}>
          <Input
            value={userInfo.staffName || '-'}
            style={inputStyle}
            readOnly
          />
        </FormItem>
        {
          applyType === 0 &&
          <>
            <FormItem style={{ marginBottom: 4 }} label="维修公司">
              {getFieldDecorator('maintenanceCompany', { rules: [{ required: false, message: '请输入维修公司名称' }] })(
                <Input placeholder="请输入维修公司名称" />
              )}
            </FormItem>
            <FormItem style={{ marginBottom: 4 }} label="维修经办人">
              {getFieldDecorator('maintenanceHandler', { rules: [{ required: true, message: '请输入维修经办人' }] })(
                <Input placeholder="请输入维修经办人姓名" />
              )}
            </FormItem>
            <FormItem style={{ marginBottom: 4 }} label="故障说明">
              {getFieldDecorator('faultDescription', { rules: [{ required: true, message: '请输入故障说明信息' }] })(
                <TextArea placeholder="请输入故障说明信息" />
              )}
            </FormItem>
          </>
        }
        {
          applyType === 1 && <>
            <FormItem style={{ marginBottom: 4 }} label="报废说明">
              {getFieldDecorator('disposalDescription', { rules: [{ required: false, message: '请输入报废说明信息' }] })(
                <TextArea placeholder="请输入报废说明信息" />
              )}
            </FormItem>
            <FormItem label="附件" {...formItemLayout}>
              <div className="dragger-box" ref={draggerRef}>
                <Dragger
                  {...uploadFiles}
                >
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                  <p className="ant-upload-hint">
                    附件支持格式：GIF、PNG、JPG、pptx、PDF。单个文件大小不超过5M，不超过5个附件。
                  </p>
                </Dragger>
              </div>
            </FormItem>
          </>
        }
        <FormItem wrapperCol={{ span: 24, style: { textAlign: 'center' } }}>
          <Button onClick={handleReset} style={{ marginRight: 8 }}>取消</Button>
          <Button loading={saveLoading} type="primary" htmlType="submit">确认</Button>
        </FormItem>
      </Form>
    </div>
  )
}

export default Form.create()(ReturnDeviceForm)
